import React, { useState, useEffect } from "react";
import "../CSS/Header.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SetSMSnotyfication } from "../store/UserReducer";
import Clock from "react-live-clock";
function Header({ history }) {
  const QR = useSelector((e) => e.UserReducer.qr);
  const URL = useSelector((e) => e.UserReducer.url);
  const IsActive = useSelector((e) => e.UserReducer.restaurant_status);
  const LOGO = useSelector((e) => e.UserReducer.allData.img.logo);
  const SMSnotyfication = useSelector((e) => e.UserReducer.SMSnotyfication);
  const SMSLeft = useSelector((e) => e.UserReducer.SMSLeft);
  const dispatch = useDispatch();
  const [ModeBool, setModeBool] = useState();
  const [IsOpenBool, setIsOpenBool] = useState();
  const [IsOpenTxt, setIsOpenTxt] = useState("");
  const redirectNav = () => {
    history.push("/");
  };

  const [StyleRightNavBar, setStyleRightNavBar] = useState("translateX(100%)");
  const changeStyleNav = () => {
    if (StyleRightNavBar === "translateX(100%)") {
      setStyleRightNavBar("translateX(0)");
    } else if (StyleRightNavBar === "translateX(0)") {
      setStyleRightNavBar("translateX(100%)");
    }
  };
  const [SMSnotButtText, setSMSnotButtText] = useState("");
  const [SMSnotTitleText, setSMSnotTitleText] = useState("");
  useEffect(() => {
    getdata();
  }, []);
  const getdata = () => {
    axios
      .get(`${URL}/get_open_close.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        setModeBool(response.data.is_open.auto_open_close);
        setIsOpenBool(response.data.is_open.is_open);
        setIsOpenBoolQR(response.data.is_open.is_open_qr);
        SetSMSnotyfication(
          response.data.sms_notifications.sms_notifications,
          dispatch
        );
        if (response.data.is_open.is_open) {
          setIsOpenTxt("Zamknij");
          setColor("#1ea38c");
        } else {
          setIsOpenTxt("Otwórz");
          setColor("#c0222e");
        }
        if (response.data.is_open.auto_open_close) {
          setModeTxt("Auto");
        } else {
          setModeTxt("Manual");
        }
        if (response.data.sms_notifications.sms_notifications) {
          setSMSnotButtText("Wyłącz");
          setSMSnotTitleText("Włączone");
        } else {
          setSMSnotButtText("Włącz");
          setSMSnotTitleText("Wyłączone");
        }
        if (response.data.is_open.is_open_qr) {
          setIsOpenQRTxt("Zamknij");
          setColorQR("#1ea38c");
        } else {
          setIsOpenQRTxt("Otwórz");
          setColorQR("#c0222e");
        }
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const [IsOpenBoolQR, setIsOpenBoolQR] = useState(true);
  const [IsOpenQRTxt, setIsOpenQRTxt] = useState("");
  const [ColorQR, setColorQR] = useState("");
  const SwitchIsOpenQR = () => {
    if (IsOpenBoolQR) {
      axios
        .post(
          `${URL}/set_open_close.php`,
          {
            is_open: {
              is_open_qr: !IsOpenBoolQR,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setIsOpenBoolQR(!IsOpenBoolQR);
            setIsOpenQRTxt("Otwórz");
            setColorQR("#c0222e");
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    } else {
      axios
        .post(
          `${URL}/set_open_close.php`,
          {
            is_open: {
              is_open_qr: !IsOpenBoolQR,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //   //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setIsOpenBoolQR(!IsOpenBoolQR);
            setIsOpenQRTxt("Zamknij");
            setColorQR("#1ea38c");
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    }
  };
  const SwitchIsOpen = () => {
    if (IsOpenBool) {
      axios
        .post(
          `${URL}/set_open_close.php`,
          {
            is_open: {
              is_open: !IsOpenBool,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setIsOpenBool(!IsOpenBool);
            setIsOpenTxt("Otwórz");
            setColor("#c0222e");
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    } else {
      axios
        .post(
          `${URL}/set_open_close.php`,
          {
            is_open: {
              is_open: !IsOpenBool,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setIsOpenBool(!IsOpenBool);
            setIsOpenTxt("Zamknij");
            setColor("#1ea38c");
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    }
  };
  const [ModeTxt, setModeTxt] = useState("");
  const [Color, setColor] = useState("");
  const Switch = (e) => {
    if (e) {
      axios
        .post(
          `${URL}/set_open_close.php`,
          {
            is_open: {
              auto_open_close: true,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setModeTxt("Auto");
            setModeBool(true);
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    } else {
      axios
        .post(
          `${URL}/set_open_close.php`,
          {
            is_open: {
              auto_open_close: false,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setModeTxt("Manual");
            setModeBool(false);
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    }
  };

  const switchSMSnotyfication = () => {
    if (SMSnotyfication) {
      axios
        .post(
          `${URL}/set_open_close.php`,
          { sms_notifications: { sms_notifications: false } },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setSMSnotButtText("Włącz");
            setSMSnotTitleText("Wyłączone");
            getdata();
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    } else {
      axios
        .post(
          `${URL}/set_open_close.php`,
          { sms_notifications: { sms_notifications: true } },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setSMSnotButtText("Wyłącz");
            setSMSnotTitleText("Włączone");
            getdata();
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    }
  };

  return (
    <div
      className="container-fluid header height fixed-top"
      style={{ height: "70px" }}
    >
      <div
        className="container  py-sm-1 py-0 d-flex position-relative height"
        style={{ height: "70px" }}
      >
        <img
          onClick={redirectNav}
          style={{ cursor: "pointer" }}
          src={LOGO}
          id="logo"
          className="d-block  mr-sm-4 mr-2"
        />

        <span className="d-flex align-items-center">
          <span>
            <p className="h5 Bold upper mb-0 mb-sm-2" id="header_text_1">
              <Clock format={"HH:mm:ss"} ticking={false} />
            </p>
            {IsActive ? (
              SMSnotyfication === true ? (
                SMSLeft > 0 ? (
                  <p
                    className=" Bold text-success upper mb-0 mb-sm-2"
                    id="header_text_1"
                  >
                    Pozostało {SMSLeft} SMS
                  </p>
                ) : (
                  <p
                    className=" text-danger h6 Bold upper mb-0 mb-sm-2 text-break"
                    id="header_text_1"
                  >
                    Wstrzymanno powiadomienia SMS. DOŁADUJ SMS!
                  </p>
                )
              ) : (
                ""
              )
            ) : (
              <p
                className=" text-danger h6 Bold upper mb-0 mb-sm-2 text-break"
                id="header_text_1"
              >
                Zamawianie online zostało zablokowne!
              </p>
            )}
          </span>
        </span>

        <span className="">
          <svg viewBox="0 0 48 33.349" id="menu-row" onClick={changeStyleNav}>
            <g transform="translate(9.814)">
              <g transform="translate(-7.314 22.93)">
                <circle
                  style={{
                    fill: Color,
                  }}
                  className="circle-a"
                  cx="5.209"
                  cy="5.209"
                  r="5.209"
                  transform="translate(31.256)"
                />
                <circle
                  style={{
                    fill: Color,
                  }}
                  className="circle-b"
                  cx="5.209"
                  cy="5.209"
                  r="5.209"
                  transform="translate(15.628)"
                />
                <circle
                  style={{
                    fill: Color,
                  }}
                  className="circle-c"
                  cx="5.209"
                  cy="5.209"
                  r="5.209"
                />
              </g>
              <text className="d" transform="translate(-9.814 15)">
                <tspan x="0" y="0">
                  MENU
                </tspan>
              </text>
            </g>
          </svg>
        </span>
      </div>
      <div
        id="right-navbar"
        className="py-4 px-2  flex-column"
        style={{ transform: StyleRightNavBar }}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 20.001 20.001"
          onClick={changeStyleNav}
          id="menu-x"
          className="mr-4"
        >
          <path
            id="Icon"
            d="M10,20A10,10,0,1,1,20,10,10.012,10.012,0,0,1,10,20ZM10,2a8,8,0,1,0,8,8A8.009,8.009,0,0,0,10,2ZM6.41,15h0L5,13.59,8.59,10,5,6.41,6.411,5,10,8.59,13.59,5,15,6.41,11.41,10,15,13.59,13.591,15,10,11.41,6.411,15Z"
          />
        </svg>

        <div className="row w-100 pt-4 mx-0">
          <div className="col-12 px-0 text-left ">
            <p className="my-0 Bold">Zamawianie Online</p>
          </div>
          <div className="col-12 pl-4 text-left my-2">
            <div class=" custom-switch ">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitch1"
                checked={ModeBool}
                onChange={(e) => Switch(e.target.checked)}
              />
              <label class="custom-control-label" for="customSwitch1">
                {ModeTxt}
              </label>
            </div>
          </div>
          <div className="col-12 pl-4 my-2 text-left">
            <button
              type="button"
              onClick={SwitchIsOpen}
              disabled={ModeBool}
              class={
                ModeBool
                  ? "btn btn-info"
                  : IsOpenBool
                  ? "btn btn-danger"
                  : "btn btn-success"
              }
            >
              {IsOpenTxt}
            </button>{" "}
          </div>
        </div>
        <div className="row w-100 pt-4 pb-2 mx-0 border-bottom">
          <div className="col-12 px-0 text-left ">
            <p className="my-0 Bold">Bezdotykowe Menu</p>
          </div>

          <div className="col-12 pl-4 my-2 text-left">
            {QR ? (
              <button
                type="button"
                onClick={SwitchIsOpenQR}
                disabled={ModeBool}
                class={IsOpenBoolQR ? "btn btn-danger" : "btn btn-success"}
              >
                {IsOpenQRTxt}
              </button>
            ) : (
              <div class="alert alert-success" role="alert">
                Odblokuj opcję
              </div>
            )}
          </div>
        </div>
        <div className="row w-100 pt-4 pb-2 mx-0 border-bottom">
          <div className="col-12 px-0 text-left ">
            <p className="my-0 Bold">Powiadomienia SMS ({SMSnotTitleText})</p>
          </div>{" "}
          <div className="col-12 pl-4 my-2 text-left">
            <button
              type="button"
              class={
                SMSnotButtText === "Wyłącz"
                  ? "btn btn-danger mb-0"
                  : "btn btn-success mb-0"
              }
              onClick={() => switchSMSnotyfication()}
            >
              {SMSnotButtText}
            </button>
          </div>{" "}
        </div>
        <div className="row w-100 pt-4 mx-0">
          <div className="col-12  text-center">
            <div class="alert alert-primary" role="alert">
              <p className="Bold">Zgłoś problem</p>
              <p className="Lite">support@flexmenu.pl</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Header);
