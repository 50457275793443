import React, { Component } from "react";
import ReactDOM from "react-dom";
import makeAnimated from "react-select/animated";
import { default as ReactSelect } from "react-select";
import Select, { components, OptionProps } from 'react-select';
 

export default function MultiSelect(props) {
  const handleChange = (e) => {
    console.log(e);
    props.selectedOptionsChange(e);
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = "red";
      return {
        ...styles,
        backgroundColor: isDisabled ? 'white' : 'white',
        color: 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  // const Option = (props) => {
  //   return (
  //     <div>
  //       <components.Option {...props}>
       

  //         <div className="flex justify-between">
      
  //     X
  //           <label>{props.label}</label>
  //           {
  //           props.isSelected ?   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  //           <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z" fill="#4F46E5"/>
  //           </svg>
  //           : ""
  //         }
  //           </div>

  //       </components.Option>
  //     </div>
  //   );
  // };

  
const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  return (
    <Select
    //components={{Option}}
      // isMulti={true}
      // options={props.options}
      // placeholder={'Tagi'}
          // onChange={handleChange}
      // closeMenuOnSelect={false}
      // hideSelectedOptions={false}
      // styles={colourStyles}
       value={props.selectedOptions}
  
      isMulti
      name="colors"
      options={props.options}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={handleChange}

      
      
    />
  );
}