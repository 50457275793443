export default function  selectIconsHandler  (e,setIcons,setOptions) {
    setOptions(e);

    let arrTmp = {};

    
     for(let i=0; i< e.length;i++){
      arrTmp[i+1] =e[i].value.replace("/img/icons/", "")
    }

    // setIconsArray(arrTmp);
    setIcons(arrTmp);

  };