import React, { useEffect, useState } from 'react'

import BreadcrumbNav from '../BreadcrumbsNav/BreadcrumbsNav'
import CalculateOptions from '../CalculateOptions/CalculateOptions'

export default function ChooseCalculate({ getFinalVal }) {
	const [getValue, setGetValue] = useState()

	const types = [
		{ type: 'distance', id: 1 },
		{ type: 'city', id: 2 },
		{ type: 'timeOfTravel', id: 3 },
	]

	const setType = val => {
		setGetValue(val)

		getFinalVal(val)
	}

	return (
		<>
			<div className='m-auto '>
				<div className='max-w-xl'>
					<BreadcrumbNav />
					<p className='text-darkgray font-bold text-xl'>
						Wybierz sposób wyliczania stawek za dostawę który najbardziej pasuje
						do Twojej restauracji.
					</p>
					<p className='text-gray text-sm'>
						<strong>Wskazówka: </strong>Aby wrócić do tego wyboru, usuń stworzone strefy dostaw.
					</p>
				</div>

				{types.map(type => (
					<CalculateOptions idx={type.id} type={type.type} setType={setType} key={`${type}_${type.id}`}/>
				))}
			</div>
		</>
	)
}
