import React from "react";
import List from "./List";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import HeaderNew from "./HeaderNew";
import NavNew from "./NavNew";
import EditMealForm from "./TailwindComponents/EditMealForm/EditMealForm,";
import AddMealForm from "./TailwindComponents/AddMealForm/AddMealForm";
import { useState } from "react";

export default function Menu() {
  const [SelectedCategory, setSelectedCategory] = useState("");
  const [SelectedMeal, setSelectedMeal] = useState("");

  const [SelectedCategoryId, setSelectedCategoryId] = useState("");
  const [SelectedMealId, setSelectedMealId] = useState("");
  const [NewMealAdding, setNewMealAdding] = useState(false);
  const history = useHistory();
  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column h-100">
        <div id="content" className="bg-white">
          <NavNew />

          <div className="container-fluid">
            <div
              className="container-fluid display  px-0 position-relative "
              style={{ background: "white", minHeight: window.innerHeight }}
            >
              <div className="h-900px w-1440px flex">
                <div className="w-384px border-r border-lightgray">
                  <div>
                    <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i pl-3">
                      Twoje menu
                    </p>
                  </div>

                  <List
                    selectedCategoryIDMenu={ SelectedCategoryId }
                    selectCategoryAndDish={(data) => {
                      setSelectedCategory(data.categoryName);
                      setSelectedMeal(data.dishName);
                      setSelectedCategoryId(data.categoryId);
                      setSelectedMealId(data.dishId);
                      setNewMealAdding(false);
                    }}
                    addNewMealToCategory={(data) => {
                      setSelectedCategory(data.categoryName);
                      setSelectedCategoryId(data.categoryId);
                      setNewMealAdding(true);
                    }}
                    
                  />

                  <button
                    type="button"
                    className="w-50 h-8 items-center px-1 mb-1 mt-3 ml-1 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-darkIndigo bg-lightIndigo hover:bg-indigo hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      history.push("/menu/addcategory");
                    }}
                  >
                    + Dodaj kategorię
                  </button>
                </div>

                {SelectedCategoryId && !NewMealAdding && (
                  <div className="w-976px bg-white">
                    <EditMealForm
                      category={SelectedCategory}
                      categoryId={SelectedCategoryId}
                      dish={SelectedMeal}
                      dishId={SelectedMealId}
                    />
                  </div>
                )}

                {NewMealAdding &&  <AddMealForm 
                category={SelectedCategory}
                categoryId={SelectedCategoryId}
                ></AddMealForm>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
