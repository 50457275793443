import VerticalBarChart from './VerticalBarChart';
import PieChart from './PieChart';
import LineChart from './LineChart';
import axios from "axios";
import React, { useState, useEffect } from "react";
import Chart from 'chart.js/auto';
import HeaderNew from "../Components/HeaderNew";

const Charts = () => {
  const [isLoading, setLoading] = useState(true);
  const [barCharts, setBarCharts] = useState();
  const [pieCharts, setPieCharts] = useState();
  const [lineCharts, setLineCharts] = useState();

  useEffect(() => {
    setLoading(true);
    axios.get("https://resmanager.srv22208.microhost.com.pl/sqlite/get_chart.php").then(response => {
      setBarCharts(response.data['bar']);
      setPieCharts(response.data['pie']);
      setLineCharts(response.data['line']);
      setLoading(false);
    });
  }, []);

  return (
    <>
    <HeaderNew/>
    {isLoading ? <div className="App"></div>: (
      <div style={{display: 'flex', width: '100%'}}>
      
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        {Object.keys(barCharts).map((chartName) => (
          <div style={{
            width: '864px',
            marginTop: '40px',
            marginLeft: '100px',
          }}>
            <VerticalBarChart
              name={barCharts[chartName].name}
              labels={barCharts[chartName].labels}
              datasets={barCharts[chartName].datasets}
            />

          </div>
        ))}
        {Object.keys(pieCharts).map((chartName) => (
          <div style={{
            width: '664px',
            marginLeft: '100px',
            marginTop: '40px'
          }}>
            <PieChart
              name={pieCharts[chartName].name}
              labels={pieCharts[chartName].labels}
              datasets={pieCharts[chartName].datasets} />
          </div>
        ))}
        {Object.keys(lineCharts).map((chartName) => (
          <div style={{
            width: '864px',
            marginTop: '40px',
            marginBottom: '40px',
            marginLeft: '100px',
          }}>
            <LineChart
              name={lineCharts[chartName].name}
              labels={lineCharts[chartName].labels}
              datasets={lineCharts[chartName].datasets} />
          </div>
        ))}
      </div>
  </div>
    )}
    
    </>
    
  );
}

export default Charts;