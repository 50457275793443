import React from "react";
import { Route } from "react-router-dom";
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { withRouter,NavLink,useLocation } from 'react-router-dom'



import { useHistory } from "react-router-dom";


const SettingsNavNew = (props) =>
{
  let history = useHistory();


  const redirectTexts = e => {
		e.preventDefault()
		history.push('/customize/texts')
	}
	const redirectStyle = e => {
		e.preventDefault()
		history.push('/customize/style')
	}
	const redirectAlerts = e => {
		e.preventDefault()
		history.push('/customize/alerts')
	}
	const redirectCompanyInfo = e => {
		e.preventDefault()
		history.push('/customize/companyinfo')
	}
	const redirectIcons = e => {
		e.preventDefault()
		history.push('/customize/icons')
	}
	const redirectDelivery = e => {
		e.preventDefault()
		history.push('/customize/delivery')
    
	}


  const reddirectTo = (e,path) => {
    e.preventDefault()
		history.push(path)

  }
  const location = useLocation();

 

   
 

    return <>
 

 
    <Disclosure as="nav" className="bg-white   overflow-x-auto   ">
       
        <>
          <div className=" w-auto mx-auto px-2 sm:px-6 lg:px-8    ">
           
                <p className="text-darkgray font-bold text-2xl pt-3 font-weight: 700 font-i ml-6 px-4 my-1">
                 {props.title}
                </p>
              
            <div className="relative flex flex-nowrap justify-between h-16  ">
             
          
              <div className=" flex flex-nowrap items-center justify-center   ">
                
                <div className="  ml-6 flex flex-nowrap    ">
            
                 
                          {
                            props.tabs.map(({name,path} )=>   <a

                            
                            className={`${location.pathname==path ? "border-indigo border-b-4   text-indigo" :"border-gray border-b-2 text-gray-500 " } no-underline inline-flex items-center text-[14px] w-auto px-6  hover:no-underline  text-[14px] font-medium`}
                            href="#"
                            key={name}   
                          
                              
                              onClick={e => {
                              reddirectTo(e,path)
                            }}
                          >
                            {name}
                          </a>)
                        }
                        

                </div>
              </div>
              
            </div>
          </div>

          
        </>
      
    </Disclosure>
 
    
    
    </>
}
export default withRouter(SettingsNavNew);