import React, { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import Cookies from "js-cookie";
import Orders from "./Components/Orders";
import EndOrders from "./Components/EndOrders";
import Email from "./Components/Email";
import QR from "./Components/QR";
import PushNotifi from "./Components/PushNotifi";
import "./CSS/Loading.css";

import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import LoginPanel from "./Components/LoginPanel";
import AdminPanel from "./Components/AdminPanel";
import { SetMenuCategory, SetMenuToEdit } from "./store/MenuReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  SetRestaurantStatus,
  SetQR,
  AllData,
  SetCoupons,
  SetResteurantCode,
  SetSMSLeft,
  SetpricePerSMS,
  Setsender,
  SetIsSMSActive,
  SetAcceptsPickupInPerson,
} from "./store/UserReducer";
import { SetaAtLocation, setDelivery } from "./store/OrderReducer";
import AddMeal from "./Components/AddMeal";
import AddCategory from "./Components/AddCategory";
import AddAdditions from "./Components/AddAdditions";
import EditMenu from "./Components/EditMenu";
import Menu from "./Components/Menu";

import EditCategory from "./Components/EditCategory";
import EditMeal from "./Components/EditMeal";
import Delivery from "./Components/Delivery";
import Cupons from "./Components/Cupons";
import Texts from "./Components/Texts";
import Style from "./Components/Style";
import CompanyInfo from "./Components/CompanyInfo";
import IconNames from "./Components/IconNames";
import CustomerManagement from "./Components/CustomerManagement";
import SMS from "./Components/SMS";
import NewOrder from "./Components/IndoorOrder/NewOrder";
import Charts from "./Components/Charts";
import Alerts from "./Components/Alerts";

function App() {
  //Cookies.set('IsLoggedIn',true)

  const getMenuToEdit = async () => {
    await axios
      .get(`${SERVER_URL}/${Code}/edit_menu`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // //console.log(response.data);
        if (SetMenuToEdit(response.data, dispatch)) {
          // //console.log("ooocholera");
        }
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const getAllMenu = async () => {
    await axios
      .get(`${URL}/get_menu.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //  //console.log(response.data);
        if (
          SetMenuCategory(response.data.menu, dispatch) &&
          SetCoupons(response.data.coupons, dispatch)
        ) {
          // //console.log("jest");
        }
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };

  const URL = useSelector((e) => e.UserReducer.url);

  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  const [Loading, setLoading] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(navigator);
    getAllMenu();
    getAllTexts();
    getDeliveryPlaces();
  }, []);

  const getQRStatus = () => {
    axios
      .get(`${SERVER_URL}/${Code}/restaurant `, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        SetQR(response.data.is_qr_active, dispatch);
        SetRestaurantStatus(response.data.is_active, dispatch);
        SetIsSMSActive(response.data.is_sms_active, dispatch);
        SetAcceptsPickupInPerson(
          response.data.accepts_pickup_in_person,
          dispatch
        );
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const set = () => {
    axios
      .get(`${URL}/set_orders.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // //console.log(response.data);
        setLoading(0);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const getDeliveryPlaces = async () => {
    let stationary = [];
    await axios
      .get(`${URL}/get_delivery_places.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // //console.log(response.data);
        if (setDelivery(response.data, dispatch)) {
          {
            Object.keys(response.data.places).map((element) => {
              if (response.data.places[element].is_stationary === true) {
                stationary.push(response.data.places[element]);
              }
            });
          }
        }
      })

      .catch((err) => {
        //console.log(err.response);
      });

    SetaAtLocation(stationary, dispatch);
  };

  const sms_left = () => {
    axios
      .get(`${SERVER_URL}/${Code}/sms_left `, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        SetSMSLeft(response.data.sms_left, dispatch);
        //SetSMSLeft(0, dispatch);
        SetpricePerSMS(response.data.sms_price, dispatch);
        Setsender(response.data.sender, dispatch);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };

  const getAllTexts = async () => {
    await axios
      .get(`${URL}/get_website_data.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // //console.log(response.data);

        if (
          AllData(response.data, dispatch) &&
          SetResteurantCode(response.data.restaurant_info.code, dispatch)
        ) {
          // //console.log("jest");
        }
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const Code = useSelector((e) => e.UserReducer.resteurantCode);
  useEffect(() => {
    if (Code !== null) {
      Delete();
      getMenuToEdit();
      sms_left();
      getQRStatus();
    }
  }, [Code]);
  const Delete = async () => {
    // await axios
    //   .delete(`${SERVER_URL}/${Code}/ordersp24`, {
    //     "Content-Type": "application/json",
    //   })
    //   .then((response) => {
    //     // //console.log(response.data);
    //     set();
    //   })

    //   .catch((err) => {
    //     //console.log(err.response);
    //   });
    set();
  };
  const LoginRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Redirect to="/" />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );

  const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const OrdersRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const EndOrdersRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );

  const AddMealRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const AddCategoryRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const AddAdditionsRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const EditMenuRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );

  const MenuRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const EditCategoryRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const EditMealRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );

  const CustomizeDeliveryRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const CampaignsCuponsRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );

  const CustomizeTextsRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const CustomizeStyleRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );

  const CustomizeAlertsRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const CustomizeCompanyInfoRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const CustomizeIconsRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const CampaignsEmailRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const CampaignsCustomerManagementRoute = ({
    component: Component,
    ...rest
  }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const CampaignsSMSRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const QRRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const PushNotification = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const CreateNewOrder = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
  const ChartRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Cookies.get("IsLoggedIn")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );

  if (Loading) {
    return (
      <div className="bodyLoad">
        <div className="loading">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="wrapper">
        <Router>
          <Switch>
            <ProtectedRoute path="/" exact component={AdminPanel} />
            <OrdersRoute path="/orders" component={Orders} />
            <EndOrdersRoute path="/endorders" component={EndOrders} />
            <LoginRoute path="/login" component={LoginPanel} />
            <AddMealRoute path="/menu/addmeal" component={AddMeal} />
            <AddCategoryRoute
              path="/menu/addcategory"
              component={AddCategory}
            />
            <AddAdditionsRoute
              path="/menu/addadditions"
              component={AddAdditions}
            />
            <EditMenuRoute path="/menu/editmenu" exact component={EditMenu} />
            <MenuRoute path="/menu" exact component={Menu} />
            <EditCategoryRoute
              path="/menu/editmenu/editcategory/:id"
              component={EditCategory}
            />
            <EditMealRoute
              path="/menu/editmenu/editmeal/:id/:categoryID"
              component={EditMeal}
            />

            <CustomizeDeliveryRoute
              exact
              path="/customize/delivery"
              component={Delivery}
            />
            <CustomizeTextsRoute path="/customize/texts" component={Texts} />
            <CustomizeStyleRoute path="/customize/style" component={Style} />
            <CustomizeAlertsRoute path="/customize/alerts" component={Alerts} />
            <CustomizeCompanyInfoRoute
              path="/customize/companyinfo"
              component={CompanyInfo}
            />

            <CampaignsCuponsRoute path="/campaigns/cupons" component={Cupons} />
            <CampaignsEmailRoute path="/campaigns/email" component={Email} />
            <CampaignsSMSRoute path="/campaigns/sms" component={SMS} />
            <CampaignsCustomerManagementRoute
              path="/campaigns/customermanagement"
              component={CustomerManagement}
            />
            <CustomizeIconsRoute
              path="/customize/icons"
              component={IconNames}
            />
            <QRRoute path="/qr" component={QR} />
            <PushNotification
              path="/campaigns/pushnotification"
              component={PushNotifi}
            />
            <CreateNewOrder path="/neworder" component={NewOrder} />
            <ChartRoute path="/charts" component={Charts} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
