import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Row } from 'react-bootstrap'
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import DeleteModal from '../../DeleteModal/DeleteModal'

export default function ActiveZonesKM({ updateState, delivery, getDeliveryPlaces, setChoosenDeliveryType }) {
	const ResteurantCode = useSelector(e => e.UserReducer.resteurantCode)
	const SERVER_URL = useSelector(e => e.UserReducer.server_URL)
	const URL = useSelector(e => e.UserReducer.url)
	const [Delivery, setDelivery] = useState(null)
	const [updated, setUpdated] = useState(updateState)
	const [id, setId] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [deleted, setDeleted] = useState(false)
	const [distance, setDistance] = useState(false)
	const [idToDelete, setIdToDelete] = useState(null)
	const [deliveryPrice, setDeliveryPrice] = useState(0);
	const [deliveryRange, setDeliveryRange] = useState(0);
	const [deliveryFree, setDeliveryFree] = useState(0);
	const [deliveryPriceError, setDeliveryPriceError] = useState(false);
	const [deliveryRangeError, setDeliveryRangeError] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setUpdated(updateState)
		setDelivery(delivery);
		setDistance();
		setLoading(false);
	}, [])

	useEffect(() => {
		if(delivery !== null){
			if(Object.keys(delivery).length > 0){
				setDelivery(delivery);
			} else {
				setDelivery(null);
			}
		}
	}, [delivery])

	const removeCity = async id => {
		axios
			.delete(`${SERVER_URL}/${ResteurantCode}/delivery_range/${id}`)
			.then(response => {
				if (response.status === 204) {
					getDeliveryPlaces().then(response => {
						if (Object.keys(response).length === 0) {
							setChoosenDeliveryType('');
						}
					});
				}
			})
			.catch(err => {})
	}

	const checkAndSetDeliveryPrice = (price) => {
		// if (price === '' && deliveryPrice.length > 1) {
		// 	setDeliveryPrice(price);
		// 	return;
		// }
		setDeliveryPrice(price);
		if(price === ''){
			setDeliveryPriceError(true);
		} else {
			setDeliveryPriceError(false);
		}
	}

	const checkAndSetDeliveryName = (name) => {
		setDeliveryRange(name);
		if(name === ''){
			setDeliveryRangeError(true);
		} else {
			setDeliveryRangeError(false);
		}
	}

	const editCity = element => {
		console.log(element);
		setId(element.id);
		setDeliveryRange(element.max_distance / 1000);
		setDeliveryPrice(element.delivery_price);
		setDeliveryFree(element.free_above);
	}

	const updateDeliveryRange = async () => {
		let json = {
			id: id,
			max_distance: deliveryRange * 1000,
			delivery_price: parseInt(deliveryPrice),
			free_above: parseInt(deliveryFree)
		}
		await axios
		.patch(`${SERVER_URL}/${ResteurantCode}/delivery_range/${id}`, json, {
			'Content-Type': 'application/json',
		})
		.then(response => {
			if (response.status === 200) {
				getDeliveryPlaces();
				setId(null);
				setDeliveryRange(null);
				setDeliveryPrice(null);
				setDeliveryFree(null);
			}
		})
		.catch(err => {})
	}

	const isDeleted = value => {
		setDeleted(value)
		setShowModal(false)
		if (value == true) {
			removeCity(idToDelete)
		}
	}

	return (
		<>
			{Delivery !== null ? (
				Object.keys(Delivery).map(element => {
						return (
							<>
								<div
									className={`${
										Delivery[element].id == id ? 'bg-gray-100' : ''
									}
										'w-full flex flex-col px-3 py-2 mb-3 border rounded-md '`}
										key={`el_${element.id}`}>
									<div className='grid grid-cols-12 gap-4 w-full'>
										<div className='col-span-3 mb-6 md:mb-0 grow'>
											<p className='block text-sm font-normal text-gray m-0'>
												Zakres
											</p>
											<div className='text-base font-bold text-darkgray'>
												{Delivery[element].max_distance / 1000} km
											</div>
										</div>
										<div className='col-span-3  mb-6 md:mb-0 grow'>
											<p className='block text-sm font-normal text-gray m-0'>
												Cena dostawy
											</p>
											<div className='text-base font-bold text-darkgray'>
												{`${Delivery[element].delivery_price} zł`}
											</div>
										</div>
										<div className='col-span-3 mb-6 md:mb-0 grow'>
											<p className='block text-sm font-normal text-gray m-0'>
												Darmowa dostawa
											</p>
											<div className='text-base font-bold text-darkgray'>
												{`${Delivery[element].free_above == null ? 'Brak' : `${Delivery[element].free_above} zł`} `}
											</div>
										</div>
										<div className='col-span-2 mb-6 md:mb-0 grow flex'>
											{Delivery[element].id == id ? (
												<button
													type='button'
													disabled={deliveryRangeError || deliveryPriceError}
													className='h-5 p-3 disabled:opacity-50 focus:ring-indigo-500 disabled:pointer-events-none focus:border-indigo-500 block  sm:text-sm border-gray-300 mx-auto rounded-md inline-flex items-center  float-right   border border-transparent text-basefont-medium rounded-md shadow-sm text-white bg-indigo hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
													onClick={() => updateDeliveryRange()}>
													Zapisz
												</button>
											) : (
												<>
													<div
														className='grid grid-cols-2 gap-2 w-full py-2'
														onClick={() => {
															setShowModal(true)
															setIdToDelete(Delivery[element].id)
														}}>
														<div className='p-2.5 m-auto'>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																style={{ height: '20px', width: '20px' }}
																className=' h-5 w-5 '
																fill='none'
																viewBox='0 0 24 24'
																stroke='currentColor'
																strokeWidth='2'>
																<path
																	strokeLinecap='round'
																	strokeLinejoin='round'
																	d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'
																/>
															</svg>
														</div>
													</div>
													<div
														className='grid grid-cols-2 gap-2 w-full py-2'
														onClick={() => editCity(Delivery[element])}>
														<div className='p-2.5 m-auto'>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																style={{ height: '20px', width: '20px' }}
																className=' h-5 w-5 '
																fill='none'
																viewBox='0 0 24 24'
																stroke='currentColor'
																strokeWidth='2'>
																<path
																	strokeLinecap='round'
																	strokeLinejoin='round'
																	d='M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z'
																/>
															</svg>
														</div>
													</div>{' '}
												</>
											)}
										</div>
									</div>

									{Delivery[element].id == id ? (
										<div className='grid grid-cols-12 gap-4 w-full' key={`element_${element.id}`}>
											<div className='col-span-3  mb-6 md:mb-0 grow'>
													<div>
														<label
															htmlFor='price'
															className='block text-sm font-normal text-gray'></label>
														<div className='mt-1 relative rounded-md shadow-sm'>
															<input
																type='text'
																name='price'
																id='price'
																value={deliveryRange}
																placeholder=''
																onChange={e => {
																	checkAndSetDeliveryName(e.target.value)
																}}
																className={`${deliveryRangeError? `border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500`: `focus:ring-indigo-500 focus:border-indigo-500 border-gray-300`} block w-full pl-7 pr-12 px-3 sm:text-sm  rounded-md`}
																aria-describedby='price-currency'
															/>
															<div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
																{' '}
																<span
																	className='text-gray-500 sm:text-sm'
																	id='price-currency'>
																	KM
																</span>
															</div>
														</div>
														{deliveryRangeError && 	<p className="mt-2 mb-0 text-sm text-red-600" id="price-currency">
															Podaj zasięg strefy
     											</p>}
													</div>
											</div>
											<div className='col-span-3  mb-6 md:mb-0 grow'>
												<label
													htmlFor='price'
													className='block text-sm font-normal text-gray'></label>
												<div className='mt-1 relative rounded-md shadow-sm'>
													<input
														type='number'
														id='basic-url'
														value={deliveryPrice}
														onChange={(e) => checkAndSetDeliveryPrice(e.target.value)}
														className={`block w-full pr-10 ${deliveryPriceError ? `border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500` : `focus:ring-indigo-500 focus:border-indigo-500 border-gray-300`}  sm:text-sm rounded-md`}
														aria-describedby='basic-addon3'
													/>
													<div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
														<span
															className='text-gray-500 sm:text-sm'
															id='price-currency'>
															PLN
														</span>
													</div>
												</div>
												{deliveryPriceError && 	<p className="mt-2 mb-0 text-sm text-red-600" id="email-error">
															Podaj koszt dostawy
     											</p>}
											</div>
											<div className='col-span-3  mb-6 md:mb-0 grow'>
												<label
													htmlFor='price'
													className='block text-sm text-gray'></label>
												<div className='mt-1 relative rounded-md shadow-sm'>
													<input
														type='number'
														name='price'
														id='basic-url'
														value={deliveryFree}
														onChange={(e) => setDeliveryFree(e.target.value)}
														className='focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 pl-7 pr-12 sm:text-sm border-gray-300 rounded-md'
														aria-describedby='price-currency'
													/>
													<div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
														<span
															className='text-gray-500 sm:text-sm'
															id='price-currency'>
															PLN
														</span>
													</div>
												</div>
											</div>{' '}
										</div>
									) : (
										''
									)}
								</div>

								{showModal ? (
									<DeleteModal isDeleted={isDeleted} cancel={setShowModal} city={`${Delivery[element].max_distance / 1000} km`}/>
								) : (
									''
								)}
							</>
						)
				})
			) : (
				<p>
					Aktualnie nie posiadasz żadnych stref dostaw, uzupełnij pola powyżej
					aby je dodać do Twojego systemu.
				</p>
			)}
		</>
	)
}
