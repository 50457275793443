import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import SingleOrder from "./SingleOrder";
import {
  SetLengthOrder,
  SetshowModal,
  SetSMSLeft,
  SetpricePerSMS,
  Setsender,
  SetsellecTedOrder,
} from "../store/UserReducer";
import NavNew from "./NavNew";
import { Howl } from "howler";
import HeaderNew from "../Components/HeaderNew";
import CircleCheck from "../img/circle-checked.svg";
import Circle from "../img/circle-x.svg";
import Discount from "../img/discount.svg";
import Icon from "../img/icon.svg";
import PinStart from "../img/pin-start.svg";
import Pin from "../img/pin.svg";
import Route from "../img/route.svg";

import "../CSS/Orders.css";
export default function Orders() {
  const ShowModal = useSelector((e) => e.UserReducer.showModal);
  const URL = useSelector((e) => e.UserReducer.url);
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  const SellectedOrder = useSelector((e) => e.UserReducer.sellecTedOrder);
  //const Bool = useSelector((e) => e.UserReducer.lengthOrder);
  const Bool = useSelector((e) => e.UserReducer.lengthOrder);
  const [Orderss, setOrderss] = useState();
  const [Orders2, setOrders] = useState([]);
  const [interval_counter, setIntervalCounter] = useState(0);
  const SMSLeft = useSelector((e) => e.UserReducer.SMSLeft);
  const SMSnotyfication = useSelector((e) => e.UserReducer.SMSnotyfication);
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const Sender = useSelector((e) => e.UserReducer.sender);
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const [loading, setLoading] = useState(false);
  const [SMSText, setSMSText] = useState("");
  const [curentProgress, setCurentProgress] = useState(null);

  const [kolejka, setKolejka] = useState(false);
  const [kuchnia, setKuchnia] = useState(false);
  const [gotowe, setGotowe] = useState(false);
  const [zakonczone, setZakonczone] = useState(false);

  const [naMiejscu, setNaMiejscu] = useState(false);
  const [naWynos, setNaWynos] = useState(false);
  const [dostawa, setDostawa] = useState(false);

  const [zaplacono, setZaplacono] = useState(false);
  const [nieZaplacono, setNieZaplacono] = useState(false);

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("filters"));
    console.log(filters);
    if (filters !== undefined && filters !== null) {
      setKolejka(filters.kolejka);
      setKuchnia(filters.kuchnia);
      setGotowe(filters.gotowe);
      setZakonczone(filters.zakonczone);
      setNaMiejscu(filters.naMiejscu);
      setNaWynos(filters.naWynos);
      setDostawa(filters.dostawa);
      setZaplacono(filters.zaplacono);
      setNieZaplacono(filters.nieZaplacono);
    }
  }, []);
  const setLSFilters = (filters) => {
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setSMSText(UserInfo.editable_texts.sms_order_notification);
    }
  }, [UserInfo]);

  const sms_left = () => {
    axios
      .get(`${SERVER_URL}/${ResteurantCode}/sms_left `, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        SetSMSLeft(response.data.sms_left, dispatch);
        SetpricePerSMS(response.data.sms_price, dispatch);
        Setsender(response.data.sender, dispatch);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const random = () => {
    let cod =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    ////console.log("Wyrandowany kod:" + cod);
    return cod;
  };

  const smsSpend = () => {
    axios
      .put(
        `${SERVER_URL}/${ResteurantCode}/sms_sent `,
        {
          quantity: 1,
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response);
        sms_left();
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const sendSMS = (element) => {
    let control = random();
    axios
      .post(
        `https://api.hostedsms.pl/FullApi/Smses`,
        {
          Phone: ["48" + element.phone_number],
          Message: SMSText,
          Sender: Sender,
          TransactionId: control,
          Priority: "3",
          FlashSms: false,
        },
        {
          headers: {
            "User-Agent": "Fiddler",
            Authorization:
              "Basic ZmVlZHdlYi5jb250YWN0QGdtYWlsLmNvbTpLYXdhc2FraTA4",
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (
          response.data.ErrorMessage === undefined &&
          response.data.MessageIds !== undefined
        ) {
          //console.log(response);
          smsSpend();
        } else {
          console.log(response);
          //smsSpend();
          // setTimeout(function () {}, 1000);
        }
      })

      .catch((err) => {
        console.log(err.response);
      });
  };
  const pushNotification = (device_id, subject, text) => {
    console.log(device_id);
    console.log(subject);
    console.log(text);
    let date = new Date();
    let date_time = date.getTime();
    if (
      device_id !== undefined &&
      device_id !== "" &&
      subject !== undefined &&
      subject !== "" &&
      text !== undefined &&
      text !== ""
    ) {
      axios
        .post(
          `${URL}/notifications/order_notification.php`,
          {
            to_who: device_id,
            title: subject,
            body: text,
            sentTime: date_time,
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const moveStepOne = async (element) => {
    setLoading(true);
    await axios
      .put(
        `${SERVER_URL}/${ResteurantCode}/orderp24/${element.id}`,
        {
          id: element.id,
          restaurants_order_id: element.restaurants_order_id,
          progress: "3",
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data === "OK") {
          setCurentProgress("3");
          if (SMSLeft > 0 && SMSnotyfication === true) {
            sendSMS(element);
          }
          change();
          if (element.device_id !== undefined) {
            pushNotification(
              element.device_id,
              "Cześć",
              "Twoje zamówienie jest w przygotowaniu!"
            );
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });
  };
  const moveStepTwo = async (element) => {
    setLoading(true);
    await axios
      .put(
        `${SERVER_URL}/${ResteurantCode}/orderp24/${element.id}`,
        {
          id: element.id,
          restaurants_order_id: element.restaurants_order_id,
          progress: "4",
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data === "OK") {
          setCurentProgress("4");
          if (element.device_id !== undefined) {
            pushNotification(
              element.device_id,
              "Cześć",
              "Twoje zamówienie jest w drodze!"
            );
          }
          change();
        }
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err.response);
        setLoading(false);
      });
  };
  const moveStepThree = async (element) => {
    setLoading(true);
    await axios
      .put(
        `${SERVER_URL}/${ResteurantCode}/orderp24/${element.id}`,
        {
          id: element.id,
          restaurants_order_id: element.restaurants_order_id,
          progress: "5",
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.status === 200 && response.data === "OK") {
          setCurentProgress("5");
          //console.log("tak");
          //  pushNotification(element.device_id,'STEP3','STEP3')
          change();
          handleClose(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err.response);
        setLoading(false);
      });
  };

  const soundPlay = (src) => {
    const sound = new Howl({
      src,
      html5: true,
    });
    sound.play();
  };
  const changeState = () => {
    let d = new Date();
    let n = d.getTime();
    setOrderss(n);
    return true;
  };
  let temp_interval_counter = 0;
  const dispatch = useDispatch();
  useEffect(() => {
    clearInterval();
    add();
    //console.log(Bool);
    let int = setInterval(() => {
      changeState();
      add();
      temp_interval_counter++;
      setIntervalCounter(temp_interval_counter);
      console.log(`Interval counter: ${temp_interval_counter}`);
      changeState();
      SetLengthOrder(1, dispatch);
    }, 10000);
    return () => clearInterval(int);
  }, []);

  let pre_update_orders_count = 0;
  let not_first_run = false;
  const add = () => {
    //  //console.log(Orders2);
    axios
      .get(`${URL}/get_orders.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response.data);

        if (response.status === 200) {
          let orders_array = [];
          Object.keys(response.data).forEach((element) => {
            orders_array.push(response.data[element]);
          });
          // orders_array.map((element) => {
          console.log(orders_array);

          // SetOrders(element, dispatch);
          ////console.log(element);
          // });
          //console.log(pre_update_orders_count);
          changeState();
          setOrders(orders_array);
          if (
            pre_update_orders_count !== Object.keys(response.data).length &&
            not_first_run
          ) {
            if (changeState()) {
              changeState();
              soundPlay(`${URL}/mp3/ring.mp3`);
              changeState();
            }
            //console.log(pre_update_orders_count);
          }
          not_first_run = true;
          pre_update_orders_count = orders_array.length;
          //  //console.log("kurwaaa");
        }
        changeState();
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        changeState();
        change();
        setTimeout(() => {
          add();
        }, 500);
      });
  };

  const change = () => {
    console.log("kurwaaa");
    axios
      .get(`${URL}/get_orders.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log(response.data);

        if (response.status === 200) {
          console.log("kurwaaa");
          let orders_array = [];
          Object.keys(response.data).forEach((element) => {
            orders_array.push(response.data[element]);
          });
          setOrders(orders_array);

          // orders_array.map((element) => {
          //console.log(orders_array);

          // SetOrders(element, dispatch);
          ////console.log(element);
          // });
        }
        changeState();
      })
      .catch((err) => {
        console.log(err.response);
        console.log(err.request);
        console.log("Error", err.message);
        changeState();
      });
  };
  // useEffect(() => {
  // if (ResteurantCode !== null) {
  // axios
  // .get(
  // `${SERVER_URL}/${ResteurantCode}/orders`,
  // {
  //  "Content-Type": "application/json",
  //}
  //)
  //.then((response) => {
  //  if (response.status === 200) {
  //   Object.keys(response.data).map((element) => {
  //   SetOrderss(response.data[element], dispatch);
  // setOrderss(response.data[element]);
  //  //console.log(element);
  // });
  // }
  //})
  //.catch((err) => {
  //  //console.log(err);
  //});
  // }
  //}, [ResteurantCode]);

  const handleClose = (x) => {
    SetshowModal(x, dispatch);
    setCurentProgress(null);
  };

  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <div className="container-fluid pl-2 pr-0">
            <div className="wrapper d-block">
              <div
                className="row py-md-2 py-1 w-100 mx-0"
                style={{
                  height: window.innerHeight - 120,
                }}
              >
                <div className="whitespace-nowrap overflow-x-auto filter-bar pl-1 mb-1">
                  <div className="w-max inline-block align-top mr-3">
                    <span className="mb-0 text-xs  Bold">
                      Status zamówienia
                    </span>
                    <div>
                      <button
                        onClick={() => {
                          setKolejka(!kolejka);
                          setLSFilters({
                            kolejka: !kolejka,
                            kuchnia: kuchnia,
                            gotowe: gotowe,
                            zakonczone: zakonczone,
                            naMiejscu: naMiejscu,
                            naWynos: naWynos,
                            dostawa: dostawa,
                            zaplacono: zaplacono,
                            nieZaplacono: nieZaplacono,
                          });
                        }}
                        className={
                          kolejka
                            ? "btn inline-block bg-warning  float-left border-warning mr-2 text-white py-0 px-1 Font15px mb-1"
                            : "btn inline-block   float-left border-warning mr-2 text-warning py-0 px-1 Font15px mb-1"
                        }
                      >
                        <i
                          class={
                            kolejka
                              ? "demo-icon icon-clock text-white mx-0"
                              : "demo-icon icon-clock text-warning mx-0"
                          }
                        >
                          &#xe802;
                        </i>{" "}
                        Kolejka
                      </button>

                      <button
                        onClick={() => {
                          setKuchnia(!kuchnia);
                          setLSFilters({
                            kolejka: kolejka,
                            kuchnia: !kuchnia,
                            gotowe: gotowe,
                            zakonczone: zakonczone,
                            naMiejscu: naMiejscu,
                            naWynos: naWynos,
                            dostawa: dostawa,
                            zaplacono: zaplacono,
                            nieZaplacono: nieZaplacono,
                          });
                        }}
                        className={
                          kuchnia
                            ? "btn inline-block bg-primary whitespace-normal float-left border-primary mr-2 text-white py-0 px-1 Font15px mb-1"
                            : "btn inline-block  whitespace-normal float-left border-primary mr-2 text-primary py-0 px-1 Font15px mb-1"
                        }
                      >
                        <i
                          class={
                            kuchnia
                              ? "demo-icon icon-reserve text-white "
                              : "demo-icon icon-reserve text-primary "
                          }
                        >
                          &#xe806;
                        </i>
                        Kuchnia
                      </button>

                      <button
                        onClick={() => {
                          setGotowe(!gotowe);
                          setLSFilters({
                            kolejka: kolejka,
                            kuchnia: kuchnia,
                            gotowe: !gotowe,
                            zakonczone: zakonczone,
                            naMiejscu: naMiejscu,
                            naWynos: naWynos,
                            dostawa: dostawa,
                            zaplacono: zaplacono,
                            nieZaplacono: nieZaplacono,
                          });
                        }}
                        className={
                          gotowe
                            ? "btn inline-block whitespace-normal bg-success float-left border-success mr-2 text-white py-0 px-1 Font15px mb-1"
                            : "btn inline-block whitespace-normal float-left border-success mr-2 text-success py-0 px-1 Font15px mb-1"
                        }
                      >
                        <i
                          class={
                            gotowe
                              ? "demo-icon icon-box text-white"
                              : "demo-icon icon-box text-success"
                          }
                        >
                          &#xe800;
                        </i>{" "}
                        Gotowe
                      </button>

                      <button
                        onClick={() => {
                          setZakonczone(!zakonczone);
                          setLSFilters({
                            kolejka: kolejka,
                            kuchnia: kuchnia,
                            gotowe: gotowe,
                            zakonczone: !zakonczone,
                            naMiejscu: naMiejscu,
                            naWynos: naWynos,
                            dostawa: dostawa,
                            zaplacono: zaplacono,
                            nieZaplacono: nieZaplacono,
                          });
                        }}
                        className={
                          zakonczone
                            ? "btn inline-block whitespace-normal bg-danger float-left border-danger mr-2 text-white py-0 px-1 Font15px mb-1"
                            : "btn inline-block whitespace-normal float-left border-danger mr-2 text-danger py-0 px-1 Font15px mb-1"
                        }
                      >
                        <i
                          class={
                            zakonczone
                              ? "demo-icon icon-clipboard-tick text-white"
                              : "demo-icon icon-clipboard-tick text-denger"
                          }
                        >
                          &#xe801;
                        </i>{" "}
                        Zakończone
                      </button>
                    </div>
                  </div>
                  <div className="w-max inline-block align-top mr-3">
                    <span className="mb-0 text-xs Bold">Rodzaj dostawy</span>
                    <div>
                      <button
                        onClick={() => {
                          setNaMiejscu(!naMiejscu);
                          setLSFilters({
                            kolejka: kolejka,
                            kuchnia: kuchnia,
                            gotowe: gotowe,
                            zakonczone: zakonczone,
                            naMiejscu: !naMiejscu,
                            naWynos: naWynos,
                            dostawa: dostawa,
                            zaplacono: zaplacono,
                            nieZaplacono: nieZaplacono,
                          });
                        }}
                        class={
                          naMiejscu
                            ? "btn border-dark bg-dark text-white mr-2 py-0 px-1 Font15px mb-1"
                            : "btn border-dark mr-2 py-0 px-1 Font15px mb-1"
                        }
                      >
                        <i class="demo-icon icon-gps mr-1">&#xe804;</i> Na
                        miejscu
                      </button>

                      <button
                        onClick={() => {
                          setNaWynos(!naWynos);
                          setLSFilters({
                            kolejka: kolejka,
                            kuchnia: kuchnia,
                            gotowe: gotowe,
                            zakonczone: zakonczone,
                            naMiejscu: naMiejscu,
                            naWynos: !naWynos,
                            dostawa: dostawa,
                            zaplacono: zaplacono,
                            nieZaplacono: nieZaplacono,
                          });
                        }}
                        class={
                          naWynos
                            ? "btn border-dark bg-dark text-white mr-2 py-0 px-1 Font15px mb-1"
                            : "btn border-dark mr-2 py-0 px-1 Font15px mb-1"
                        }
                      >
                        <i class="demo-icon icon-location mr-1">&#xe805;</i> Na
                        wynos
                      </button>

                      <button
                        onClick={() => {
                          setDostawa(!dostawa);
                          setLSFilters({
                            kolejka: kolejka,
                            kuchnia: kuchnia,
                            gotowe: gotowe,
                            zakonczone: zakonczone,
                            naMiejscu: naMiejscu,
                            naWynos: !naWynos,
                            dostawa: dostawa,
                            zaplacono: zaplacono,
                            nieZaplacono: nieZaplacono,
                          });
                        }}
                        class={
                          dostawa
                            ? "btn border-dark bg-dark text-white mr-2 py-0 px-1 Font15px mb-1"
                            : "btn border-dark mr-2 py-0 px-1 Font15px mb-1"
                        }
                      >
                        <i class="demo-icon icon-routing mr-1">&#xe807;</i>{" "}
                        Dostawa
                      </button>
                    </div>
                  </div>
                  <div className="w-max inline-block align-top mr-3">
                    <span className="mb-0 text-xs Bold">Płatność</span>
                    <div>
                      <button
                        onClick={() => {
                          setZaplacono(!zaplacono);
                          setLSFilters({
                            kolejka: kolejka,
                            kuchnia: kuchnia,
                            gotowe: gotowe,
                            zakonczone: zakonczone,
                            naMiejscu: naMiejscu,
                            naWynos: naWynos,
                            dostawa: dostawa,
                            zaplacono: !zaplacono,
                            nieZaplacono: nieZaplacono,
                          });
                        }}
                        class={
                          zaplacono
                            ? "btn border-dark bg-dark text-white mr-2 py-0 px-1 Font15px mb-1"
                            : "btn border-dark mr-2 py-0 px-1 Font15px mb-1"
                        }
                      >
                        <i class="demo-icon icon-tick-square mr-1">&#xe808;</i>{" "}
                        Zapłacone
                      </button>

                      <button
                        onClick={() => {
                          setNieZaplacono(!nieZaplacono);
                          setLSFilters({
                            kolejka: kolejka,
                            kuchnia: kuchnia,
                            gotowe: gotowe,
                            zakonczone: zakonczone,
                            naMiejscu: naMiejscu,
                            naWynos: naWynos,
                            dostawa: dostawa,
                            zaplacono: zaplacono,
                            nieZaplacono: !nieZaplacono,
                          });
                        }}
                        class={
                          nieZaplacono
                            ? "btn border-dark bg-dark text-white mr-2 py-0 px-1 Font15px mb-1"
                            : "btn border-dark mr-2 py-0 px-1 Font15px mb-1"
                        }
                      >
                        <i class="demo-icon icon-close-square mr-1">&#xe803;</i>{" "}
                        Nie zapłacone
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 px-0  h-100 "
                  style={{
                    height: window.innerHeight - 120,
                  }}
                >
                  <div className="w-50 mx-0 float-left">
                    {Orders2.filter((order) => {
                      if (!kolejka && !kuchnia && !gotowe && !zakonczone) {
                        return true;
                      }
                      if (kolejka && order.progress === "2") {
                        return true;
                      }
                      if (kuchnia && order.progress === "3") {
                        return true;
                      }
                      if (gotowe && order.progress === "4") {
                        return true;
                      }
                      if (zakonczone && order.progress === "5") {
                        return true;
                      }
                    })
                      .filter((order) => {
                        if (!naMiejscu && !naWynos && !dostawa) {
                          return true;
                        }
                        return true;
                        //#TODO
                      })
                      .filter((order) => {
                        if (!zaplacono && !nieZaplacono) {
                          return true;
                        }
                        if (zaplacono && order.payment_type === "1") {
                          return true;
                        }
                        if (nieZaplacono && order.payment_type === "2") {
                          return true;
                        }
                        //#TODO
                      })
                      .map((element, index) => {
                        if (index % 2 === 0) {
                          return (
                            <SingleOrder
                              element={element}
                              stepOne={moveStepOne}
                              stepTwo={moveStepTwo}
                              stepThree={moveStepThree}
                              setCurentProgress={setCurentProgress}
                            />
                          );
                        }
                      })}
                  </div>
                  <div className="w-50 mx-0 float-left">
                    {Orders2.filter((order) => {
                      if (!kolejka && !kuchnia && !gotowe && !zakonczone) {
                        return true;
                      }
                      if (kolejka && order.progress === "2") {
                        return true;
                      }
                      if (kuchnia && order.progress === "3") {
                        return true;
                      }
                      if (gotowe && order.progress === "4") {
                        return true;
                      }
                      if (zakonczone && order.progress === "5") {
                        return true;
                      }
                    })
                      .filter((order) => {
                        if (!naMiejscu && !naWynos && !dostawa) {
                          return true;
                        }
                        return true;
                        //#TODO
                      })
                      .filter((order) => {
                        if (!zaplacono && !nieZaplacono) {
                          return true;
                        }
                        if (zaplacono && order.payment_type === "1") {
                          return true;
                        }
                        if (nieZaplacono && order.payment_type === "2") {
                          return true;
                        }
                        //#TODO
                      })
                      .map((element, index) => {
                        if (index % 2 !== 0) {
                          return (
                            <SingleOrder
                              element={element}
                              stepOne={moveStepOne}
                              stepTwo={moveStepTwo}
                              stepThree={moveStepThree}
                              setCurentProgress={setCurentProgress}
                            />
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-90w ">
        <Modal
          dialogClassName="custom-modal"
          onHide={() => handleClose(false)}
          aria-labelledby="example-custom-modal-styling-title"
          show={ShowModal}
          className='relative'
          style={{
            width: "100%",
          }}
        >
          {SellectedOrder !== null ? (
            <div
              className="card-body w-full h90 b-card py-0 px-0 "
              style={{
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="row bg-white px-0 pb-20 mx-0 ">
                <div className="col-12 text-lg border-bottom  text-center py-2">
                  Zamówienie {SellectedOrder.id}
                </div>
                <div className="col-7 px-0 border-right minHeight">
                  <div className="row mx-0 pl-3 pr-0 pt-3 mb-2">
                    {SellectedOrder.meal_options.map((element, index) => {
                      if (element.additions.length === 0) {
                        return (
                          <>
                            <div className="col-8 mb-1 text-sm">
                              {" "}
                              <span className=" Bold">{element.name} </span>
                            </div>
                            <div className="col-4 mb-1 ">
                              <p className="text-right  mb-0 text-sm Bold">
                                <s className="text-secondary  mr-1 Regular">
                                  {element.price.toFixed(2)}{" "}
                                </s>
                                {element.price.toFixed(2)}
                              </p>
                            </div>
                          </>
                        );
                      } else if (element.additions.length > 0) {
                        let additions = "";
                        element.additions.map((elemant) => {
                          additions =
                            additions +
                            `<div class="col-8 mb-1 pl-4 Thin text-sm">${
                              elemant.name
                            }</div><div class="col-4 mb-2 text-right Font12px">${elemant.price.toFixed(
                              2
                            )}</div>`;
                        });

                        return (
                          <>
                            <div className="col-8 mb-1 text-sm ">
                              <span className=" Bold">{element.name}</span>
                            </div>
                            <div className="col-4 mb-1 text-sm">
                              <p className="text-right  mb-0 Bold ">
                                <s className="text-secondary Regular mr-1">
                                  {element.price.toFixed(2)}{" "}
                                </s>
                                {element.price.toFixed(2)}
                              </p>
                            </div>
                            <div
                              className="row mx-0 w-100 "
                              dangerouslySetInnerHTML={{ __html: additions }}
                            ></div>
                          </>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="col-5 px-0">
                  <div className="row mx-0 pl-0 pr-3 pt-3">
                    <div className="col-12 pl-3 mb-2 text-xs gap-x-1 flex">
                      {/* <div className="text-white bg-green-500 rounded-sm p-0.5">Opłacone</div> */}

                      {SellectedOrder.payment_type === "2" && (
                        <div className="text-white bg-red-500 rounded-sm p-0.5">
                          Do opłacenia
                        </div>
                      )}
                      
                      {SellectedOrder.payment_type === "" && (
                        <div className="text-white bg-green-500 rounded-sm p-0.5">
                          Zapłacone
                        </div>
                      )}
                       <div className="text-white bg-blue-500 rounded-sm p-0.5">
                      {SellectedOrder.is_internal === true &&
                      SellectedOrder.is_place === false ? (
                        "Na miejscu"
                      ) : SellectedOrder.is_internal === false &&
                        SellectedOrder.is_place === false ? (
                        "Odbiór osobisty"
                      ) : SellectedOrder.is_internal === false &&
                        SellectedOrder.is_place === true ? (
                        "Z dostawą"
                      ) : (
                        ""
                      )}
                        
                      </div>
                      {/* <div className="text-white bg-blue-500 rounded-sm p-0.5">Dostawa</div> */}
                    </div>

                    <div className="col-8 pl-3 mb-3 text-sm">
                      {SellectedOrder.ordered_time},{" "}
                      {SellectedOrder.ordered_date}
                    </div>
                    <div className="col-4 px-0 Thin  text-right text-xs ">
                      {/* <p className="inline-block align-middle mb-0">
                        Usuń zamówienie{" "}
                      </p> */}
                    </div>

                    <div className="col-12 px-3 mb-2">
                      <p className="mb-0 Bold text-base">
                        {SellectedOrder.client_name}
                      </p>
                      <p className="mb-0 text-sm">
                        {SellectedOrder.delivery_city}
                      </p>
                      <p className="mb-0 text-sm">
                        {SellectedOrder.delivery_address}9
                      </p>
                      <p className="mb-0 text-sm">
                        {SellectedOrder.phone_number}
                      </p>
                      <p className="mb-1 text-sm">{SellectedOrder.email}</p>
                    </div>

                    {SellectedOrder.comment ? (
                      <div className="col-12 px-3 mb-2">
                        <p className="mb-1 text-xs Thin">Komentarz</p>
                        <p className="mb-0 text-sm">{SellectedOrder.comment}</p>
                      </div>
                    ) : null}
                    <div className="col-7 pl-3 pr-0">
                      <p className="mb-0 text-lg Bold">
                        {SellectedOrder.to_pay}zł
                      </p>
                    </div>
                    <div className="col-5 px-0 text-right">
                      {/* <p className="mb-0 text-xs Thin text-right inline-block align-middle ">
                        Szczególy płatności
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          ) : null}
           <div className="bg-white bottomBar  text-xl border-top flex  text-center px-3 justify-between py-2 fixed  bottom-0">
                {curentProgress !== "4" && (
                  <button
                    onClick={() => {
                      moveStepThree(SellectedOrder);
                    }}
                    disabled={loading}
                    type="button"
                    class="disabled:bg-red-500 items-center rounded border border-transparent bg-red-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 text-center w-24"
                  >
                    {loading ? (
                      <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      <>
                        <span>Zakończ</span>
                      </>
                    )}
                  </button>
                )}

                {curentProgress === "2" && (
                  <button
                    onClick={() => {
                      moveStepOne(SellectedOrder);
                    }}
                    disabled={loading}
                    type="button"
                    class="disabled:bg-blue-500 items-center rounded border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-center w-32"
                  >
                    {loading ? (
                      <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      <>
                        <span>Przygotowywane</span>
                      </>
                    )}
                  </button>
                )}
                {curentProgress === "3" && (
                  <button
                    onClick={() => {
                      moveStepTwo(SellectedOrder);
                    }}
                    disabled={loading}
                    type="button"
                    class="disabled:bg-green-500 items-center rounded border border-transparent bg-green-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 text-center w-32"
                  >
                    {loading ? (
                      <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      <>
                        <span>Gotowe</span>
                      </>
                    )}
                  </button>
                )}
                {curentProgress === "4" && (
                  <button
                    onClick={() => {
                      moveStepThree(SellectedOrder);
                    }}
                    disabled={loading}
                    type="button"
                    class="disabled:bg-red-500 items-center rounded border border-transparent bg-red-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 text-center w-32"
                  >
                    {loading ? (
                      <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      <>
                        <span>Zakończ</span>
                      </>
                    )}
                  </button>
                )}
              </div>
        </Modal>
       
      </div>
    </>
  );
}
