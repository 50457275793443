import React, { useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function ToogleSwitch({
	name,
	enabled,
	onChange,
	description
}) {

	return (
		<Switch.Group as='div' className='flex items-center justify-between'>
			<span className='flex-grow flex flex-col'>
				<Switch.Label
					as='span'
					className='text-darkgray font-bold text-base mr-2'
					passive>
					{name}
				</Switch.Label>
				<Switch.Description as='span' className='text-sm text-gray-500'>
					{description}
				</Switch.Description>
			</span>
			<Switch
				checked={enabled}
				onChange={() => {
					onChange()
				}}
				name='pickUp'
				className={classNames(
					enabled ? 'bg-indigo border-indigo' : 'bg-gray-200 border-lightgray ',
					'relative inline-flex flex-shrink-0 h-6 w-11 border-2 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none  focus:ring-indigo-500'
				)}>
				<span
					aria-hidden='true'
					className={classNames(
						enabled ? 'translate-x-5' : 'translate-x-0',
						'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
					)}
				/>
			</Switch>
		</Switch.Group>
	)
}
