import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import HeaderNew from "../HeaderNew";
import NavNew from '../NavNew';
import { addMeal, deleteMeal } from "../../store/OrderReducer";
import { useSelector,useDispatch } from "react-redux";
import '../../CSS/NewOrder.css';
import round from '../../Functions/round';
import axios from "axios";

export default function NewOrder({history}) {

  const menu = useSelector((e) => e.MenuReducer.menu);
  const [cities] = useState(["Dąbrowa Tarnowska", "Smęgorzów", "Niwki"]);
  const [currentMenu, setCurrentMenu] = useState(menu[0].meal_bases);
  const [stolik] = useState(["1", "2", "3", "4", "5"]);
  const [choosenStolik, setChoosenStolik] = useState();
  const [choosenStolikId, setChoosenStolikId] = useState();
  const [rabat, setRabat] = useState();
  const [deliveryChecked, setDeliveryChecked] = useState(false);
  const [regular, setRegular] = useState();
  const [city, setCity] = useState(); 
  const [showModal, setShowModal] = useState(false);
  const [currentMeal, setCurrentMeal] = useState([]);
  const [currentSizedMeal, setCurrentSizedMeal] = useState();
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const [currentAdditions, setCurrentAdditions] = useState([]);
  const [chosenAdditions, setChosenAdditions] = useState([]);
  const [test, setTest] = useState(false);
  //const [order, setOrder] = useState([{name: "TESTOWE DANIE", price: 20}, {name: "TESTOWE DANIE2", price: 15, additions: [{name: "pierwszy dodatek", price: 20}, {name: "drugi dodatek", price: 20}]}]);
  const dispatch = useDispatch();
  const orders = useSelector((e) => e.OrderReducer.order);
  const RestaurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const IsOpen =  useSelector((e) => e.UserReducer.isOpen);
  const tables = useSelector ((e) => e.OrderReducer.atLocation)
const SERVER_URL = useSelector((e) => e.UserReducer.server_URL)

  useEffect(() => {
    setCurrentAdditions(menu[0].addition_categories);
    let temp = [];
    menu[0].addition_categories.map(e => {
      e.additions.map(ee => {
        temp.push({id: ee.id, amount: 0, name: ee.name, price: ee.price});
      })
    })
    setChosenAdditions(temp);
    var firstButton = document.getElementById(menu[0].id);
    firstButton.style.background = "#007BFF"
  }, []);


  /*
      delivery_city: City.id,
      client_name: Name + " " + Surname,
      delivery_address: Street + " " + BuildNr,
      phone_number: Phone,
      email: Email,
      ordered_date: await dataCrator(),
      ordered_time: await timeCrator(),
      to_pay: price.toFixed(2),
      delivery_price: is_api === false ? Ammount - Off < FreeAbove ? DeliveryAmmount : 0 : Ammount - Off < freeAbove ? deliveryAmmount : 0,
      comment: Coment,
      preparation_time: "",
      promotion_code: Code,
      payment_control: control,
      payment_type: "",
      payment_status: "",
      progress: "",
      meal_options: await mealCreator(),
      marketing_permit: Agree2,
      distance: IntDistance,
      device_id: DeviceID
  */
      const random = async () => {
        let cod =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
    
        ////console.log("Wyrandowany kod:" + cod);
        return cod;
      };

      const optionsCreator = (add) => {
        let additions = [];
        Object.keys(add).map((element) => {
          additions.push({
            addition: add[element].id,
            quantity: add[element].amount
          });
        });
        //// console.log(additions);
        return additions;
      };

      const mealCreator = () => {
        //// console.log(Order);
        let meals = [];
        Object.keys(orders).map((element) => {
          meals.push({
            meal_option: orders[element].idOption,
            quantity: 1,
            additions: optionsCreator(orders[element].additions),
          });
        });
        //// console.log(meals);
        return meals;
      };

      const submitOfflinePay = async (totalPrice) => {
        let control = await random();
        let sendJson = ({
          delivery_city: choosenStolikId,
          ordered_date: await dataCrator(),
          ordered_time: await timeCrator(),
          to_pay: totalPrice.toFixed(2),
          delivery_price: 0,
          //comment: Coment,
          preparation_time: "",
          //promotion_code: Code,
          payment_control: control,
          payment_type: "",
          payment_status: "",
          progress: "",
          meal_options: await mealCreator(),
          marketing_permit: false,
          is_internal: true
          //distance: IntDistance,
          //device_id: DeviceID
        })
          sendJson.payment_type = "2";
          sendJson.payment_status = "2";
          sendJson.progress = "2";
          await axios
            .post(
              `${SERVER_URL}/${RestaurantCode}/ordersp24`,
              sendJson,
              {
                "Content-Type": "application/json",
              }
            )
            .then((response) => {
              console.log(response);
              // //console.log(JSON.parse(Json));
              ////console.log(Code);
              if (response.status === 201 && response.statusText === "Created") {
                alert("Zamówienie zostało złożone")
              } else if (
                response.status === 400 &&
                response.data === "Restaurant not active"
              ) {
                history.push("/error/1");
              } else {
                history.push("/error/0");
                console.log(response.data);
              }
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response);
              if (
                err.response.status === 400 &&
                err.response.data === "Restaurant not active"
              ) {
                history.push("/error/1");
              } else {
                alert("BŁĄD")
              }
            });
      };

      const dataCrator = async () => {
        let now = new Date();
    
        return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
      };
      const timeCrator = async () => {
        let now = new Date();
        let s = `${now.getMinutes()}`;
        // console.log(s);
        if (s.length == 1) {
          s = "0" + `${now.getMinutes()}`;
        }
        return `${now.getHours()}:${s}`;
      };


  const renderOrders = () => {
    let totalP = 0;
    const ordersList = orders.map((e)=>{
      totalP = totalP + e.price;
      return (
      <>
        <div className="d-flex flex-row">
          <div className="d-flex pl-3 py-2 align-items-start justify-content-center main-order">{e.name}</div>
          <div className="d-flex ml-auto pr-3 py-2 align-items-end main-order">{e.price} zł</div>
          <div className="d-flex pr-3 pt-2">
            <svg onClick={() => deleteFromOrder(e)} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="red" className="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
          </div>
          
        </div>
        {e.additions ? e.additions.map((k)=> {
          totalP = totalP + k.price;
          return (
            <div className="d-flex flex-row">
            <div className="d-flex pl-5 py-2 align-items-start additions">{k.amount > 1 ? k.amount + "x " + k.name : k.name }</div>
            <div className="d-flex ml-auto pr-3 py-2 align-items-end additions">{k.price} zł</div>
            <div style={{width: 30, height: 18}}></div>
          </div>
          )
        })
          : <></>}
        <hr className="solid"/>
      </>
    )
  })

  

    return(
      <div className="col p-1 mx-2 summary">
        <div className="col p-1 mx-2 order">
          {ordersList}
        </div>
            <div>
              <button disabled={choosenStolik === "Wybierz stolik" && !choosenStolik} onClick={() => submitOfflinePay(round(totalP))} className="btn-add-order btn btn-lg btn-success ml-5">Złóż zamówienie</button>
              <p className="txt">Koszt ostateczny: {round(totalP).toFixed(2)}zł</p>
            </div>
      </div>
    )
  }
  
  const addToOrder = (e) => {
    
    setCurrentMeal(e);
    setShowModal(true);
    // if(e.meal_options.length !== 1){
    //   setShowModal(true);
    //   setConfirmButtonDisabled(true);
    // } else {
    //   const meal = {name: e.name, price: e.meal_options[0].price, additions: [{name: "TEST", price: 20}, {name: "TEST2", price: 20}]}
    //   addMeal(meal,dispatch);
    // }
  }

  const addToOrderWithSizeOptions = (e, fullName, adds) => {
    setShowModal(false);
    console.log("fullName: " + e);
    let name;
    e.name === null ? name = "" : name = e.name; 
    const meal = adds ? {idOption: e.id, name: `${fullName} ${name}`, price: e.price, additions: adds} : {idOption: e.id, name: `${fullName} ${e.name}`, price: e.price}
    addMeal(meal,dispatch);
  }

  const deleteFromOrder = (e) => {
    deleteMeal(e, dispatch);
  }

  const listOfDishes = () => {
    const list = Object.values(currentMenu).map((e)=>
        {
          if(e.meal_options.length !== 0){
            return (       
              <div key={e.id}  className="square d-flex col-xl-4 col-6 p-1">
                <button onClick={() => addToOrder(e)} type="button" style={{width: '100%' }} className="btn btn-outline-secondary btn-block">{e.name}</button>
              </div>
             )
          }
        }

    )
    return <div className="row">{list}</div>;
  }

  const cattegoryButtons = () => {
    const cattegoryList = Object.values(menu).map((e) => {
      return (
        <div className="mx-2" key={e.id}>
        <button id={e.id} onClick={() => {handleCattegoryChange(e)}} type="button" style={{height: '45px', whiteSpace: 'nowrap', background: "#767676" }} className="text-white btn btn-outline-secondary">{e.name}</button>
      </div>
      )
    })
    return cattegoryList
  }

  const handleCattegoryChange = (e) => {
    for (var i=0;i<menu.length;i++){
      var test = document.getElementById(menu[i].id);
      test.style.background = "#767676"
    }
    var test = document.getElementById(e.id);
    test.style.background = "#007BFF"
    setCurrentMenu(e.meal_bases);
    setCurrentAdditions(e.addition_categories);
    let temp = [];
    e.addition_categories.map(e => {
      e.additions.map(ee => {
        temp.push({id: ee.id, amount: 0, name: ee.name, price: ee.price});
      })
    })
    setChosenAdditions(temp);
  }

  const checkDeliveryBox = () => {
    var test = document.getElementById("inlineCheckbox1");
    if(deliveryChecked){
      document.getElementById("nameSurname").style.visibility = "hidden";
      document.getElementById("phoneNumber").style.visibility = "hidden";
      document.getElementById("city").style.visibility = "hidden";
      document.getElementById("street").style.visibility = "hidden";
    } else {
      document.getElementById("nameSurname").style.visibility = "visible";
      document.getElementById("phoneNumber").style.visibility = "visible";
      document.getElementById("city").style.visibility = "visible";
      document.getElementById("street").style.visibility = "visible";
    }
   
    test.checked = !test.checked

    setDeliveryChecked(test.checked);
  }

  const setDropdownInfo = (id, info) => {
    switch(id){
      case "tableButton":
        setChoosenStolik(info.name);
        setChoosenStolikId(info.id);
        document.getElementById(id).style.background = "#28a745";
        document.getElementById(id).style.borderColor = "#28a745";
        break;
      case "rabat":
        setRabat(info);
        document.getElementById(id).style.background = "#28a745";
        document.getElementById(id).style.borderColor = "#28a745";
        break;
      case "regular":
        setRegular(info);
        document.getElementById(id).style.background = "#28a745";
        document.getElementById(id).style.borderColor = "#28a745";
        break;
      case "city": 
        setCity(info);
        document.getElementById(id).style.background = "#28a745";
        document.getElementById(id).style.borderColor = "#28a745";
        break;
    }
  }

  const handleSizeSelect = (e) => {
    setConfirmButtonDisabled(false);
    console.log(currentAdditions);
    console.log(e);
    console.log(currentMeal.meal_options);
    for (var i=0;i<currentMeal.meal_options.length;i++){
      if(currentMeal.meal_options[i].id !== e.id){
        var test = document.getElementById(`sizeButton${currentMeal.meal_options[i].id}`);
        test.style.background = "#ffffff";
        test.className = "text-dark btn btn-outline-secondary mr-4";
      }
    }
    var test = document.getElementById(`sizeButton${e.id}`);
    test.style.background = "#007BFF"
    test.className = "text-white btn btn-outline-secondary mr-4";
    setCurrentSizedMeal(e);
  }

  const confirmOrder = () => {
    let temp = [];
    for (let i = 0; i<chosenAdditions.length; i++){
      if(chosenAdditions[i].amount > 0){
        let totalPrice = chosenAdditions[i].amount * chosenAdditions[i].price;
        temp.push({id: chosenAdditions[i].id, name: chosenAdditions[i].name, amount: chosenAdditions[i].amount, price: totalPrice})
      }
    }
    addToOrderWithSizeOptions(currentSizedMeal, currentMeal.name, temp);
    setConfirmButtonDisabled(true);
    clearAdditions();
  }

  const clearAdditions = () => {
    let temp = [];
    for (let i = 0; i<chosenAdditions.length; i++){
      temp.push({id: chosenAdditions[i].id, name: chosenAdditions[i].name, amount: 0, price: chosenAdditions[i].price})
    }
    setConfirmButtonDisabled(true);
    setChosenAdditions(temp);
  }

  const handleAdditionAdd = (e) => {
    let temp = chosenAdditions;
    const objIndex = temp.findIndex(x => x.id === e.id);
    temp[objIndex].amount++;
    setChosenAdditions(temp);
    var tempElement = document.getElementById(`additionButton${e.id}`);
    console.log(temp[objIndex].amount);
    if(temp[objIndex].amount === 0){
      var tempElement = document.getElementById(`additionButton${e.id}`);
      tempElement.style.background = "#ffffff"
      tempElement.className = "btn-addition d-flex justify-content-center flex-column text-dark btn btn-outline-secondary mr-4";
    }
    if(temp[objIndex].amount > 0){
      var tempElement = document.getElementById(`additionButton${e.id}`);
      tempElement.style.background = "#007BFF";
      tempElement.className = "btn-addition d-flex justify-content-center flex-column text-white btn btn-outline-secondary mr-4";
    }
    setTest(!test);
  }

  const handleAdditionSubtract = (e) => {
    let temp = chosenAdditions;
    const objIndex = temp.findIndex(x => x.id === e.id);
    if(temp[objIndex].amount > 0){
      temp[objIndex].amount--;
      setChosenAdditions(temp);
      setTest(!test);
    }
    temp[objIndex].amount--;
    setTest(!test);
  }
  

    return (
        <>
  <HeaderNew/>
  <div id="content-wrapper" className="d-flex flex-column">
    <div id="content">
     <NavNew/>
      <div className="container-fluid">
        {/* START */}
          <div className="col p-1">
            <div className="row">
            {cattegoryButtons()}
            </div>
            <div className="row pt-4">              
              <div className="scrollView col-lg-6 overflow-auto pr-0">
                {listOfDishes()}
              </div>
              <div className="col p-1">
                <div className="col p-1">
                  <div className="row d-flex ml-2">

                    <div className="dropdown col-xl-4">
                      <button style={{height: '40px', width: '100%'}} id="tableButton" className="btn btn-secondary dropdown-toggle my-2" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {choosenStolik ? choosenStolik : "Wybierz stolik"}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" onClick={() => {setChoosenStolik("Wybierz stolik"); document.getElementById("tableButton").style.background = "#6c757d"; document.getElementById("tableButton").style.borderColor = "#6c757d"}}>Brak</a>
                        {tables.map(e => {
                        return <a className="dropdown-item" onClick={() => setDropdownInfo("tableButton", e)}>{e.name}</a>
                      })}
                      </div>
                    </div>

                    <div className="dropdown col-xl-4">
                      <button style={{height: '40px', width: '100%'}} id="rabat" className="btn btn-secondary dropdown-toggle my-2" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {rabat ? rabat : "Wybierz rabat"}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" onClick={() => {setRabat("Wybierz rabat"); document.getElementById("rabat").style.background = "#6c757d"; document.getElementById("rabat").style.borderColor = "#6c757d"}}>Brak</a>
                        <a className="dropdown-item" onClick={() => setDropdownInfo("rabat", "Rabat 10%")}>10%</a>
                        <a className="dropdown-item" onClick={() => setDropdownInfo("rabat", "Rabat 20%")}>20%</a>
                        <a className="dropdown-item" onClick={() => setDropdownInfo("rabat", "Rabat 30%")}>30%</a>
                        <a className="dropdown-item" onClick={() => setDropdownInfo("rabat", "Rabat 40%")}>40%</a>
                      </div>
                    </div>

                    <div className="dropdown col-xl-4">
                      <button style={{height: '40px', width: '100%'}} id="regular" className="btn btn-secondary dropdown-toggle my-2" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {regular ? regular : "Stały klient"}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" onClick={() => {setRegular("Stały klient"); document.getElementById("regular").style.background = "#6c757d"; document.getElementById("regular").style.borderColor = "#6c757d"}}>Brak</a>
                        <a className="dropdown-item" onClick={() => setDropdownInfo("regular", "Tak")}>Tak</a>
                        <a className="dropdown-item" onClick={() => setDropdownInfo("regular", "Nie")}>Nie</a>
                      </div>
                    </div>

                  </div>
                  {/* <div className="row d-flex justify-content-between mx-2"> */}
                  <div className="row d-flex ml-2">
                    <div className="d-flex col-xl-4">
                      <button style={{ height: '40px', width: '100%'}} onClick={() => checkDeliveryBox()} className={deliveryChecked ? "btn btn-outline-success my-2 d-flex" : "d-flex btn btn-outline-secondary my-2"}>
                        <div style={{ height: '40px'}}  className="custom-control custom-checkbox custom-control-right ml-4">
                          <input onClick={() => checkDeliveryBox()} className="custom-control-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                          <label onClick={() => checkDeliveryBox()} className="custom-control-label" htmlFor="inlineCheckbox1">Dostawa</label>
                        </div>
                      </button>
                    </div>

                    <div className="d-flex dropdown col-xl-4">
                        <button style={{visibility: 'hidden', height: '40px', width: '100%'}} className="btn btn-secondary dropdown-toggle my-2 " type="button" id="city" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {city ? city : "Miasto"}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a className="dropdown-item" onClick={() => {setCity("Miasto"); document.getElementById("city").style.background = "#6c757d"; document.getElementById("city").style.borderColor = "#6c757d"}}>Brak</a>
                          {cities.map(number => {
                          return <a className="dropdown-item" onClick={() => setDropdownInfo("city", number)}>{number}</a>
                        })}
                        </div>
                    </div>
                      
                      <div className="d-flex col-xl-4">
                        <form id="street" style={{width: '100%', visibility: 'hidden'}}>
                          <div className="form-group my-2">
                            <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Ulica"/>
                          </div>
                        </form>
                      </div>
                  </div>

                  <div className="row d-flex ml-2">

                    <div className="d-flex col-xl-4">
                      <form id="phoneNumber" style={{width: '100%', visibility: 'hidden'}}>
                        <div className="form-group my-2">
                          <input type="email" className="form-control" id="exampleFormControlInput2" placeholder="Numer telefonu"/>
                        </div>
                      </form>
                    </div>

                      <div className="col-xl-8 flex-grow-1">
                          <form id="nameSurname" style={{visibility: 'hidden'}}>
                            <div className="form-group my-2 flex-grow-1">
                              <input type="email" className="form-control flex-grow" id="exampleFormControlInput3" placeholder="Imie i nazwisko"/>
                            </div>
                          </form>
                      </div>
                  </div>
                </div>
                        {renderOrders()}
              </div>
            </div>
          </div>
          
        {/* KONIEC */}
      </div>
    </div>

  </div>
  <Modal
        show={showModal}
        onHide={() => {setShowModal(false); clearAdditions();}}
        dialogClassName="custom-modal"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        animation={false}
      >
        <Modal.Header closeButton>
          {/* <h3 className="d-flex justify-content-center ml-4">{currentMeal.length !== 0 ? "Wybierz rozmiar potrawy" : "Wybierz dodatki"}</h3> */}
          <button onClick={() => confirmOrder()} disabled={confirmButtonDisabled} className="btn btn-lg btn-success ml-5">Dodaj do zamówienia</button>
        </Modal.Header>
        <Modal.Body className="px-2">

        {currentMeal.length !== 0 ? <h2 className="d-flex justify-content-start mx-0 ml-5 my-2">{currentMeal.name}</h2>: <></>}
          <div className="d-flex row justify-content-start mx-0 ml-5">
          {
            currentMeal.length !== 0 ?
              currentMeal.meal_options.map(e => {
                return(
                  <button onClick={() => handleSizeSelect(e)} style={{height: '180px', width: '180px'}} id={`sizeButton${e.id}`} className={`text-dark btn btn-outline-secondary mr-4`}>
                    <h1>{e.name}</h1>
                  </button>
              )
            }) : <></>
          }
          
        </div>
        <div>
          <p className="d-flex justify-content-start ml-5 mt-5">Dobierasz dodatki do: </p>
          <h2 className="d-flex justify-content-start ml-5">{currentMeal.name}</h2>
          {
            currentAdditions.length !== 0 ?
              currentAdditions.map(e => {
              return (
                <div>
                <div>
                  <p className="d-flex justify-content-start ml-5 mt-5">{e.name}</p>
                </div>
                <div className="d-flex flex-row ml-5">
                  {e.additions.map(ee => {
                    return(
                      <div>
                        <div id={`additionButton${ee.id}`} onClick={() => handleAdditionAdd(ee)} style={{height: '150px', width: '150px'}} className={`btn-addition d-flex justify-content-center flex-column text-dark btn btn-outline-secondary mr-4`}>
                          <p className="mb-auto">{ee.name}</p>
                          <div className="d-flex justify-content-center mb-1">
                            <button className={`text-dark btn btn-default shadow-none mb-1 px-2`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                            </button>
                            <p className="mt-2">{chosenAdditions.length !== 0 ? chosenAdditions.find(x => x.id === ee.id).amount : "FAILED" }</p>
                            <button className={`text-dark btn btn-minus btn-default shadow-none mb-1 px-2`} onClick={() => handleAdditionSubtract(ee)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-dash-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                            </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  </div>
                  </div>
              )
              }) : <></>
          }
        </div>

        </Modal.Body>
      </Modal>
        </>
    )
}
