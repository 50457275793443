import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Meals from "./Meals";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { ClearAditionsCategory } from "../store/MenuReducer";
import { useDispatch } from "react-redux";
import CategoryONOFF from "./CategoryONOFF";
import { Accordion, Card, useAccordionToggle } from "react-bootstrap";
import CustomToggle from "./CustomToggle";
export function List(props) {
  const dispatch = useDispatch();
  const Menu = useSelector((e) => e.MenuReducer.menuToEdit);
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  const [EditableMenu, setEditableMenu] = useState(null);
  const [SelectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    
    if (Menu !== null) {
      setEditableMenu(Menu.meal_categories);
      // console.log("ooocholereaaaa");
    }
  }, [Menu]);

  const [Active, setActive] = useState(true);

  const removeCategory = (id) => {
    setActive(false);
    axios
      .delete(`${SERVER_URL}/${ResteurantCode}/meal_category/${id}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // console.log(response.data);
        if (response.status === 204) {
          // console.log(response);
          window.location.reload();
          setActive(true);
        }
      })
      .catch((err) => {
        setActive(true);
        //  console.log(err.response);
      });
  };

  const [Display, setDisplay] = useState("none");
  const showRemoveCategory = () => {
    setDisplay("block");
  };
  const cancelRemoveCategory = () => {
    setDisplay("none");
  };

  const redirectEditCategory = (id) => {
    if (ClearAditionsCategory({}, dispatch)) {
      props.history.push(`/menu/editmenu/editcategory/${id}`);
    }
  };
  if (EditableMenu !== null) {
    return (
      <div className="row w-100 px-0 mx-0 position-relative">
        {Object.keys(EditableMenu).map((element) => (
          <>
          
            <Accordion defaultActiveKey="1" className="w-100 my-0">
              <div className="col-12 mt-0 px-0 mx-0 pb-2 border-b border-lightgray">
                {
                  // <CategoryONOFF
                  // id={EditableMenu[element].id}
                  // name={EditableMenu[element].name}
                  //  is_active={EditableMenu[element].is_active}
                  //  />
                }

                <div className="flex items-end justify-between w-384px  ">
                  <div className="text-darkgray font-bold text-base pt-3 font-weight: 400 font-i pl-1 ">
                    {EditableMenu[element].name}
                  </div>

                  <div className="flex">
                    <div
                      className="px-3 cursor-pointer"
                      onClick={() =>
                        redirectEditCategory(EditableMenu[element].id)
                      }
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.66675 10.7334V9.2667C1.66675 8.40003 2.37508 7.68336 3.25008 7.68336C4.75841 7.68336 5.37508 6.6167 4.61675 5.30836C4.18341 4.55836 4.44175 3.58336 5.20008 3.15003L6.64175 2.32503C7.30008 1.93336 8.15008 2.1667 8.54175 2.82503L8.63341 2.98336C9.38341 4.2917 10.6167 4.2917 11.3751 2.98336L11.4667 2.82503C11.8584 2.1667 12.7084 1.93336 13.3667 2.32503L14.8084 3.15003C15.5667 3.58336 15.8251 4.55836 15.3917 5.30836C14.6334 6.6167 15.2501 7.68336 16.7584 7.68336C17.6251 7.68336 18.3417 8.3917 18.3417 9.2667V10.7334C18.3417 11.6 17.6334 12.3167 16.7584 12.3167C15.2501 12.3167 14.6334 13.3834 15.3917 14.6917C15.8251 15.45 15.5667 16.4167 14.8084 16.85L13.3667 17.675C12.7084 18.0667 11.8584 17.8334 11.4667 17.175L11.3751 17.0167C10.6251 15.7084 9.39175 15.7084 8.63341 17.0167L8.54175 17.175C8.15008 17.8334 7.30008 18.0667 6.64175 17.675L5.20008 16.85C4.44175 16.4167 4.18341 15.4417 4.61675 14.6917C5.37508 13.3834 4.75841 12.3167 3.25008 12.3167C2.37508 12.3167 1.66675 11.6 1.66675 10.7334Z"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>

                    <CustomToggle eventKey="0" />
                  </div>
                </div>
              </div>

              <Card className="w-100 border-0 px-0">
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="w-100 border-0 px-0">
                    <button
                      type="button"
                      className="  h-18 w-100 items-center px-1 mb-1 py-2  border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-darkIndigo bg-lightIndigo hover:bg-indigo hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={(e) => {
                        props.addNewMealToCategory({
                          categoryName: EditableMenu[element].name,
                          categoryId: EditableMenu[element].id,
                        });
                      }}
                    >
                      + Dodaj potrawę
                    </button>

                 
                    <Meals

                      key={element}
                      meals={EditableMenu[element].meal_bases}
                      categoryID={element}
                      selectedCategory={SelectedCategory}
                      selectedCategoryIdMenu={props.selectedCategoryIDMenu}

                      selectCategory={(data) => {
                        setSelectedCategory(data.category);
                        props.selectCategoryAndDish({
                          categoryName: EditableMenu[element].name,
                          categoryId: element,
                          dishName: data.dish,
                          dishId: data.dishId,
                        });
                      }}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <div
              className="col-12 position-absolute popup"
              style={{
                display: Display,
              }}
            >
              <div className="row py-5 px-0 mx-0">
                <div className="col-12 mb-4 px-0 mx-0">
                  <p className="text-center Bold h5">
                    Czy chcesz usunąć wybraną kategorię?
                  </p>
                </div>
                <div className="col-6 text-center">
                  {" "}
                  <button
                    type="button"
                    class="btn btn-secondary w-100"
                    onClick={() => cancelRemoveCategory()}
                  >
                    Anuluj
                  </button>
                </div>
                <div className="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-danger w-100"
                    onClick={() => removeCategory(EditableMenu[element].id)}
                  >
                    Usuń
                  </button>{" "}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    );
  } else {
    return "";
  }
}
export default withRouter(List);
