import React, { useState, useEffect } from "react";

import PushCampainMenagment from "./PushCampainMenagment";
import NavNew from "./NavNew";
import axios from "axios";
import FileBase64 from "react-file-base64";
import { useSelector } from "react-redux";
import HeaderNew from "../Components/HeaderNew";
import SettingsNavNew from "./TailwindComponents/SettingsNavNew/SettingsNavNew";
const PushNotifi = () => {
  const URL = useSelector((e) => e.UserReducer.url);
  const [AllPush, setAllPush] = useState([]);
  const [Active, setActive] = useState(true);
  const [ComunicateText, setComunicateText] = useState("");
  const [Name, setName] = useState("");
  const [Image, setImage] = useState("");
  const [ImageName, setImageName] = useState("");
  const [Subject, setSubject] = useState("");
  const [Text, setText] = useState("");

  useEffect(() => {
    get_all_push_campain();
  }, []);
  const get_all_push_campain = () => {
    axios
      .get(`${URL}/notifications/get_notifications_for_admin.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log(response);
        setAllPush(response.data);
        console.log(response.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const addImg = () => {
    if (
      Text !== "" &&
      Name !== "" &&
      Subject !== "" &&
      Image !== "" &&
      ImageName !== ""
    ) {
      axios
        .post(
          `${URL}/add_push_image.php`,
          {
            photo_url: "/img/push_notification/" + ImageName,
            encoded_photo: Image,
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200 && response.data === "OK") {
            clear();
            createPush();
            setComunicateText("Utorzono nową kampanię email");
          } else {
            setComunicateText("Coś poszło nie tak, spróbuj ponownie");
          }
        })

        .catch((err) => {
          setComunicateText("Coś poszło nie tak, spróbuj ponownie");
          //console.log(err.response);
        });
    } else {
      if (
        Text !== "" &&
        Name !== "" &&
        Subject !== "" &&
        Image === "" &&
        ImageName === ""
      ) {
        createPush();
      } else {
        setComunicateText("Uzupełnij wszytkie pola");
      }
    }
  };
  const createPush = () => {
    let json = {};
    let date = new Date();
    let date_time = date.getTime();
    if (Image !== "" && ImageName !== "") {
      json = {
        name: Name,
        title: Subject,
        body: Text,
        image: URL + "/img/push_notification/" + ImageName,
        sentTime: date_time,
      };
    } else {
      json = {
        name: Name,
        title: Subject,
        body: Text,
        sentTime: date_time,
      };
    }
    axios
      .post(`${URL}/notifications/save_notification.php`, json, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        clear();
        console.log(response);
        if (response.status === 200) {
          get_all_push_campain();

          setComunicateText("Utworzono nowe powiadomienie");
        } else {
          setComunicateText("Coś poszło nie tak, spróbuj ponownie");
        }
      })

      .catch((err) => {
        setComunicateText("Coś poszło nie tak, spróbuj ponownie");
        console.log(err.response);
      });
  };
  const File = (files) => {
    setImage(files.base64);
    setImageName(files.name.replace(/\s+/g, ""));
  };
  const clear = () => {
    setName("");
    setSubject("");
    setText("");
    setImageName("");
    setImage("");
  };

  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <SettingsNavNew
            tabs={[
              { name: "Kupony rabatowe", path: "/campaigns/cupons" },
              { name: "Kampanie e-mail", path: "/campaigns/email" },
              { name: "Kampanie SMS", path: "/campaigns/sms" },
              {
                name: "Apilkacja mobilna ",
                path: "/campaigns/pushnotification",
              },
              { name: "Baza klientów", path: "/campaigns/customermanagement" },
            ]}
            title="Kampanie promocyjne"
          />
          <NavNew />
          <div className="container-fluid">
            <div
              className="container-fluid display  px-0 position-relative "
              style={{ background: "white", minHeight: window.innerHeight }}
            >
              <div className="max-w-7xl mx-auto ">
                <div className="pl-5 pt-1 pr-5  ">
                  <div className=" mt-2">
                    <p className="text-darkgray font-semibold text-lg pt-3 font-weight: 600 font-i">
                      Wyślij powiadomienie na aplikacje
                    </p>
                  </div>

                  <label className=" font-normal pb-1 ">Nazwa kampanii</label>
                  <div class="mb-4 relative rounded-md">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder="Nazwa kampani"
                      value={Name}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>

                  <label className=" font-normal pb-1 ">Nagłowek</label>
                  <div class="mb-4 relative rounded-md">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder="Nagłówek"
                      value={Subject}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                    />
                  </div>
                  <label className=" font-normal pb-1 ">Treść</label>
                  <div class="mb-4 relative rounded-md">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder="Treść"
                      value={Text}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setText(e.target.value);
                      }}
                    />
                  </div>

                  <div class="input-group mb-10 mt-2   opacity-0 z-10 ">
                    <FileBase64 multiple={false} onDone={File.bind()} />
                  </div>
                  <div className="flex items-start relative -top-16 left-0 ">
                  <button className="mr-2 border-borderGray border-1 px-2 rounded-md w-106px h-38px text-sm  font-medium ">
                      {" "}
                      Dodaj logo
                    </button>
                    <p>JPG lub PNG max 2mb</p>
                  </div>

                  <div className="  relative   ">
                    <div className="  absolute flex flex-col  bottom-100 right-0   mb-4 items-end">
                      <button
                        type="button"
                        className=" content-end px-3  py-2 border w-36 border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled={!Active}
                        onClick={addImg}
                      >
                        Swórz kampanię
                      </button>

                      <div className="">{ComunicateText}</div>
                    </div>
                  </div>

                  <div className=" mt-2">
                    <p className="text-darkgray font-semibold text-lg pt-3 font-weight: 600 font-i">
                      Twoje powiadomiania w aplikacji
                    </p>
                  </div>
                  <div className="flex   border-bottom   align-items-center ">
                    <div className="text-base font-bold w-40 text-left mx-1  font-weight:300 d-flex  align-items-center">
                      Nazwa kampanii
                    </div>

                    <div className=" text-base font-bold w-24 text-left mx-1  font-weight:300 d-flex px-1 align-items-center">
                      Tytuł
                    </div>
                    <div className="text-base font-bold w-44 text-left mx-1 font-weight:300 d-flex  align-items-center">
                      Treść
                    </div>
                    <div className=" text-base font-bold mb-2  border-bottom w-44 ">
                      Grafika
                    </div>
                  </div>

                  {AllPush.map((element) => {
                    if (element.type == "0") {
                      return (
                        <PushCampainMenagment
                          e={element}
                          func={get_all_push_campain}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PushNotifi;
