import React, { useState, useEffect } from "react";
import { SetAditionsCategory } from "../store/MenuReducer";
import { useDispatch } from "react-redux";
export default function CheckedElement({ checked, sendElement }) {
  const dispatch = useDispatch();
  const [Bool, setBool] = useState(null);
  const [SendElement, setSendElement] = useState(null);
  useEffect(() => {
    setBool(checked);
    setSendElement(sendElement);
    if (checked) {
      SetAditionsCategory(sendElement, dispatch);
    }

    // console.log(sendElement);
  }, [checked, sendElement]);

  const changeCheckedAdditions = (value) => {
    if (SetAditionsCategory(SendElement, dispatch)) {
      setBool(value);
    }
  };
  if (Bool !== null && SendElement !== null) {
    return (
      <div className="col-6">
        <div class="custom-control custom-checkbox mr-sm-2 my-2">
          <input
            type="checkbox"
            checked={Bool}
            onChange={(e) => changeCheckedAdditions(e.target.checked)}
            class="custom-control-input"
            id={SendElement.name + SendElement.id}
          />
          <label
            class="custom-control-label"
            for={SendElement.name + SendElement.id}
          >
            {SendElement.name}
          </label>
        </div>
      </div>
    );
  } else {
    return "";
  }
}
