export const SET_MENU_CATEGORY = "SET_MENU_CATEGORY";
export const SET_MENU_TO_EDIT = "SET_MENU_TO_EDIT";
export const SET_ADITIONS_TO_SELLECT_CATEGORY =
  "SET_ADITIONS_TO_SELLECT_CATEGORY";
export const CLEAR_SELLECT_CATEGORY = "CLEAR_SELLECT_CATEGORY";
const initialState = {
  menu: [],
  menuToEdit: null,
  aditionsCategory: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_MENU_CATEGORY": {
      const newMenu = action.payload;
      // console.log(`git ${action.type}`);
      return {
        ...state,
        menu: newMenu,
      };
    }
    case "SET_MENU_TO_EDIT": {
      // console.log(`git ${action.type}`);
      return {
        ...state,
        menuToEdit: action.payload,
      };
    }
    case "SET_ADITIONS_TO_SELLECT_CATEGORY": {
      let newAdditions = state.aditionsCategory;
      if (newAdditions[action.payload.id] !== undefined) {
        delete newAdditions[action.payload.id];
      } else {
        newAdditions[action.payload.id] = action.payload;
      }

      return {
        ...state,
        aditionsCategory: newAdditions,
      };
    }
    case "CLEAR_SELLECT_CATEGORY": {
      // console.log(`git ${action.type}`);
      return {
        ...state,
        aditionsCategory: action.payload,
      };
    }
    default: {
      // console.log(`Unknown action ${action.type}`);
      return {
        ...state,
      };
    }
  }
};

export const SetMenuCategory = (menu, dispatch) => {
  if (dispatch({ type: SET_MENU_CATEGORY, payload: menu })) {
    return true;
  }
};
export const SetMenuToEdit = (menuToEdit, dispatch) => {
  if (dispatch({ type: SET_MENU_TO_EDIT, payload: menuToEdit })) {
    return true;
  }
};
export const SetAditionsCategory = (aditionsCategory, dispatch) => {
  if (
    dispatch({
      type: SET_ADITIONS_TO_SELLECT_CATEGORY,
      payload: aditionsCategory,
    })
  ) {
    return true;
  }
};
export const ClearAditionsCategory = (aditionsCategory, dispatch) => {
  if (dispatch({ type: CLEAR_SELLECT_CATEGORY, payload: aditionsCategory })) {
    return true;
  }
};
