import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import NavNew from "./NavNew";
import CostumerInfo from "./CostumerInfo";
import HeaderNew from "../Components/HeaderNew";
import SettingsNavNew from "./TailwindComponents/SettingsNavNew/SettingsNavNew";
export default function CustomerManagement() {
  const URL = useSelector((e) => e.UserReducer.url);
  const [Data, setData] = useState([]);
  const [Email, setEmail] = useState("");
  const [City, setCity] = useState("");
  const [Adres, setAdres] = useState("");
  const [PhoneNum, setPhoneNum] = useState("");
  const [ComunicateText, setComunicateText] = useState("");
  const DUMMY_CUSTOMMER = {
    email: "ghjk.poiuyt@gmail.com",
    phone_number: "789 632 456",
    delivery_city: "Kraków",
    delivery_address: "ul.Smocza 3",
  };
  useEffect(() => {
    all_consumer_data();
  }, []);

  const addCustomer = (e) => {
    if (Email !== "" && Adres !== "" && City !== "" && PhoneNum !== "") {
      axios
        .post(
          `${URL}/sqlite/add_emails.php`,
          {
            phone_number: PhoneNum,
            email: Email,
            restaurants_order_id: null,
            delivery_address: Adres,
            delivery_city: City,
            detected_gender: null,
            payed_online: null,
            orders_number: null,
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            all_consumer_data();
            clear();

            setComunicateText("Dodano nowego klienta do bazy");
          } else {
            setComunicateText("Coś poszło nie tak, spróbuj ponownie");
          }
        })

        .catch((err) => {
          setComunicateText("Coś poszło nie tak, spróbuj ponownie");
          //console.log(err.response);
        });
    }
    {
      setComunicateText("Wpisz dane klienta");
    }
  };

  const all_consumer_data = () => {
    axios
      .get(`${URL}/sqlite/get_marketing_data.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // console.log(response);
        setData(response.data);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };

  const clear = () => {
    setEmail("");
    setPhoneNum("");
    setCity("");
    setAdres("");
  };

  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <SettingsNavNew
            tabs={[
              { name: "Kupony rabatowe", path: "/campaigns/cupons" },
              { name: "Kampanie e-mail", path: "/campaigns/email" },
              { name: "Kampanie SMS", path: "/campaigns/sms" },
              {
                name: "Apilkacja mobilna ",
                path: "/campaigns/pushnotification",
              },
              { name: "Baza klientów", path: "/campaigns/customermanagement" },
            ]}
            title="Kampanie promocyjne"
          />

          <NavNew />
          <div className="container-fluid">
            <div
              className="container-fluid display  px-0 position-relative "
              style={{ background: "white", minHeight: window.innerHeight }}
            >
              <div className="max-w-7xl mx-auto ">
                <div className="pl-5 pt-1 pr-5  ">
                  <div className=" mt-2">
                    <p className="text-darkgray font-semibold text-lg pt-3 font-weight: 600 font-i">
                      Dodawanie nowego klienta
                    </p>
                  </div>
                  <label className=" font-normal pb-1 ">E-mail</label>
                  <div class="mb-4 relative rounded-md">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder="E-mail "
                      value={Email}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>

                  <label className=" font-normal pb-1 ">Miasto</label>
                  <div class="mb-4 relative rounded-md">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder="Kraków  "
                      value={City}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                  </div>

                  <label className=" font-normal pb-1 ">Ulica</label>
                  <div class="mb-4 relative rounded-md">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder="Kraków  "
                      value={Adres}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setAdres(e.target.value);
                      }}
                    />
                  </div>

                  <label className=" font-normal pb-1 ">Numer telefonu</label>
                  <div class="mb-4 relative rounded-md">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder=" 777 888 999 "
                      value={PhoneNum}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setPhoneNum(e.target.value);
                      }}
                    />
                  </div>

                  <div className="  relative   ">
                    <div className="  absolute flex flex-col  bottom-100 right-0   mb-4 items-end">
                      <button
                        type="button"
                        className=" content-end px-3  py-2 border w-36 border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={addCustomer}
                      >
                        Dodaj klienta
                      </button>
                      <div className="">{ComunicateText}</div>
                    </div>
                  </div>

                  <div className=" mt-24">
                    <p className="text-darkgray font-semibold text-lg pt-3 font-weight: 600 font-i">
                      Dane klientów którzy zaakcepotwali zgode marketingową
                    </p>
                  </div>

                  <div className="w-full  ">
                    <div className="flex border-bottom ">
                      <div className="  font-bold text-base w-44 mr-11   pt-3 font-weight:400 d-flex align-items-left">
                        Adres e-mail
                      </div>
                      <div className=" font-bold text-base w-44 px-11 mr-2 pt-3 font-weight:400 d-flex align-items-left">
                        Numer telefonu
                      </div>
                      <div className="font-bold text-base w-44 mr-3 px-14  pt-3 font-weight:400 d-flex align-items-left">
                        Adres
                      </div>
                    </div>

                    {Data.map((element) => (
                      <CostumerInfo e={element} func={all_consumer_data} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
