import React, { useState, useEffect } from "react";
import HeaderNew from "../Components/HeaderNew";
import { SetSMSnotyfication } from "../store/UserReducer";
import { useDispatch, useSelector } from "react-redux";
import "../CSS/Mine.css";
import NavNew from './NavNew';
import axios from "axios";
export default function AdminPanel() {
  const [IsOpenBoolQR, setIsOpenBoolQR] = useState(true);
  const [IsOpenQRTxt, setIsOpenQRTxt] = useState("");
  const [ModeBool, setModeBool] = useState();
  const [IsOpenBool, setIsOpenBool] = useState();
  const [IsOpenTxt, setIsOpenTxt] = useState("");
  const [ModeTxt, setModeTxt] = useState("");
  const [Color, setColor] = useState("");
  const [ColorQR, setColorQR] = useState("");
  const [SMSnotButtText, setSMSnotButtText] = useState("");
  const [SMSnotTitleText, setSMSnotTitleText] = useState("");
  const URL = useSelector((e) => e.UserReducer.url);
  const QR = useSelector((e) => e.UserReducer.qr);
  const SMSnotyfication = useSelector((e) => e.UserReducer.SMSnotyfication);
  const dispatch = useDispatch();
  useEffect(() => {
    getdata();
  }, []);
  const getdata = () => {
    axios
      .get(`${URL}/get_open_close.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log(response);
        setModeBool(response.data.is_open.auto_open_close);
        setIsOpenBool(response.data.is_open.is_open);
        setIsOpenBoolQR(response.data.is_open.is_open_qr);
        SetSMSnotyfication(
          response.data.sms_notifications.sms_notifications,
          dispatch
        );
        if (response.data.is_open.is_open) {
          setIsOpenTxt("Zamknij");
          setColor("#1ea38c");
        } else {
          setIsOpenTxt("Otwórz");
          setColor("#c0222e");
        }
        if (response.data.is_open.auto_open_close) {
          setModeTxt("Auto");
        } else {
          setModeTxt("Manual");
        }
        if (response.data.sms_notifications.sms_notifications) {
          setSMSnotButtText("Wyłącz");
          setSMSnotTitleText("Włączone");
        } else {
          setSMSnotButtText("Włącz");
          setSMSnotTitleText("Wyłączone");
        }
        if (response.data.is_open.is_open_qr) {
          setIsOpenQRTxt("Zamknij");
          setColorQR("#1ea38c");
        } else {
          setIsOpenQRTxt("Otwórz");
          setColorQR("#c0222e");
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const SwitchIsOpen = () => {
    if (IsOpenBool) {
      axios
        .post(
          `${URL}/set_open_close.php`,
          {
            is_open: {
              is_open: !IsOpenBool,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setIsOpenBool(!IsOpenBool);
            setIsOpenTxt("Otwórz");
            setColor("#c0222e");
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    } else {
      axios
        .post(
          `${URL}/set_open_close.php`,
          {
            is_open: {
              is_open: !IsOpenBool,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setIsOpenBool(!IsOpenBool);
            setIsOpenTxt("Zamknij");
            setColor("#1ea38c");
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    }
  };


  const Switch = (e) => {
    if (e) {
      axios
        .post(
          `${URL}/set_open_close.php`,
          {
            is_open: {
              auto_open_close: true,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setModeTxt("Auto");
            setModeBool(true);
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    } else {
      axios
        .post(
          `${URL}/set_open_close.php`,
          {
            is_open: {
              auto_open_close: false,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setModeTxt("Manual");
            setModeBool(false);
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    }
  };

  const SwitchIsOpenQR = () => {
    if (IsOpenBoolQR) {
      axios
        .post(
          `${URL}/set_open_close.php`,
          {
            is_open: {
              is_open_qr: !IsOpenBoolQR,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setIsOpenBoolQR(!IsOpenBoolQR);
            setIsOpenQRTxt("Otwórz");
            setColorQR("#c0222e");
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    } else {
      axios
        .post(
          `${URL}/set_open_close.php`,
          {
            is_open: {
              is_open_qr: !IsOpenBoolQR,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //   //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setIsOpenBoolQR(!IsOpenBoolQR);
            setIsOpenQRTxt("Zamknij");
            setColorQR("#1ea38c");
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    }
  };

  const switchSMSnotyfication = () => {
    if (SMSnotyfication) {
      axios
        .post(
          `${URL}/set_open_close.php`,
          { sms_notifications: { sms_notifications: false } },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setSMSnotButtText("Włącz");
            setSMSnotTitleText("Wyłączone");
            getdata();
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    } else {
      axios
        .post(
          `${URL}/set_open_close.php`,
          { sms_notifications: { sms_notifications: true } },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data === "OK" && response.status === 200) {
            //console.log(response);
            setSMSnotButtText("Wyłącz");
            setSMSnotTitleText("Włączone");
            getdata();
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    }
  };

  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <NavNew />
          <div className="container-fluid">
            <div className="row border-bottom">
              <div className="col-2">
                <div className="row w-100 pt-4 mx-0">
                  <div className="col-12 px-0 text-left ">
                    <p className="my-0 Bold">Zamawianie Online</p>
                  </div>
                  <div className="col-12 px-0 my-2 text-left">
                    <button
                      type="button"
                      onClick={SwitchIsOpen}
                      disabled={ModeBool}
                      class={
                        ModeBool
                          ? "btn btn-info"
                          : IsOpenBool
                            ? "btn btn-danger"
                            : "btn btn-success"
                      }
                    >
                      {IsOpenTxt}
                    </button>{" "}
                  </div>
                  <div className="col-12 px-0 text-left my-2">
                    <div class=" custom-switch ">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch1"
                        checked={ModeBool}
                        onChange={(e) => Switch(e.target.checked)}
                      />
                      <label class="custom-control-label" for="customSwitch1">
                        {ModeTxt}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="row w-100 pt-4 pb-2 mx-0 ">
                  <div className="col-12 px-0 text-left ">
                    <p className="my-0 Bold">Bezdotykowe Menu</p>
                  </div>

                  <div className="col-12 px-0 my-2 text-left">
                    {QR ? (
                      <button
                        type="button"
                        onClick={SwitchIsOpenQR}
                        disabled={ModeBool}
                        class={IsOpenBoolQR ? "btn btn-danger" : "btn btn-success"}
                      >
                        {IsOpenQRTxt}
                      </button>
                    ) : (
                      <div class="alert alert-success" role="alert">
                        Odblokuj opcję
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="row w-100 pt-4  mx-0 ">
                  <div className="col-12 px-0 text-left ">
                    <p className="my-0 Bold">Powiadomienia SMS ({SMSnotTitleText})</p>
                  </div>{" "}
                  <div className="col-12 px-0 my-2 text-left">
                    <button
                      type="button"
                      class={
                        SMSnotButtText === "Wyłącz"
                          ? "btn btn-danger mb-0"
                          : "btn btn-success mb-0"
                      }
                      onClick={() => switchSMSnotyfication()}
                    >
                      {SMSnotButtText}
                    </button>
                  </div>{" "}
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  );
}
