import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import ActiveZonesKM from '../ActiveZonesKM/ActiveZonesKM'
import './style.css'
import GoogleMaps from '../../../GoogleMaps/GoogleMaps'
import { useId } from 'react'
import { Switch } from '@headlessui/react'

export default function NewDeliveryZoneFormKM({
	active,
	setShowModal,
	activeDelivery,
	showGoogleMaps,
	googleVisible,
	setChoosenDeliveryType,
	showGoogleMapsSection
}) {
	const ResteurantCode = useSelector(e => e.UserReducer.resteurantCode)
	const SERVER_URL = useSelector(e => e.UserReducer.server_URL)
	const URL = useSelector(e => e.UserReducer.url)
	const reactId = useId();
	const [Active, setActive] = useState(true)
	const [Text, setText] = useState('Dodaj ')
	const [ActiveDelivery, setActiveDelivery] = useState(false)
	const [ComunicateText, setComunicateText] = useState('')
	const [Delivery, setDelivery] = useState(null)
	const [deliveryPrice, setDeliveryPrice] = useState('')
	const [distance, setDistance] = useState('');
	const [FreeAbove, setFreeAbove] = useState('')
	const [Price, setPrice] = useState('')
	const [addedZone, setAddedZone] = useState(false)
	const [freeDelivery, setFreeDelivery] = useState('')
	const [pickUp, setPickUp] = useState(false);
	const [enabled, setEnabled] = useState(false);

	useEffect(() => {
		setPickUp(active)
		setActiveDelivery(activeDelivery)
		getDeliveryPlaces()
	}, [])

	useEffect(() => {
		setPickUp(active)
	}, [active])

	useEffect(() => {
		if(activeDelivery === false){
			setFreeDelivery('');
		} else {
			setFreeDelivery(' ');
		}	
		console.log(activeDelivery)
	}, [activeDelivery])

	const getDeliveryPlaces = () => {
		const res = axios
			.get(`${SERVER_URL}/${ResteurantCode}/delivery_ranges`, {
				'Content-Type': 'application/json',
			})
		const resData = res.then((response) => response.data);
		resData.then((data) => {
			setDelivery(data);
		});
		return resData;
	}
	const addDelivery = () => {
		if (
			distance !== '' &&
			deliveryPrice !== '' &&
			(freeDelivery !== '' || activeDelivery === true)
		) {
			setAddedZone(!addedZone)
			setActive(false)
			setText('Czekaj')
			let json = {
				max_distance: distance * 1000,
				delivery_price: parseInt(deliveryPrice),
				free_above: activeDelivery ? null : freeDelivery,
			}

			axios
				.post(`${SERVER_URL}/${ResteurantCode}/delivery_ranges`, json, {
					'Content-Type': 'application/json',
				})
				.then(response => {
					if (response.status === 201 && response.statusText === 'Created') {
						setActive(true)
						setText('Dodaj')
						setComunicateText('Dodano! Możesz teraz dodać kolejną strefę')
						setDistance('')
						setDeliveryPrice('')
						setFreeDelivery(' ')
						setFreeAbove('')
						setPrice('')
						getDeliveryPlaces()
					}
				})
				.catch(err => {})
		} else if (pickUp == true) {
			setPickUp(false)
			setAddedZone(!addedZone)

			let arr = Object.values(Delivery)
			let arrayRes = []
			arr.map(el => {
				if (el.name.indexOf('Odbiór osobisty') > -1) {
					arrayRes.push(el.name.indexOf('Odbiór osobisty') > -1)
				}
			})
			console.log(arrayRes, 'result')

			if (arrayRes.length === 0) {
				setText('Czekaj')
				let json = {
					name: 'Odbiór osobisty',
					delivery_price: 0,
					free_above: 0,
					is_place: true,
				}
				axios
					.post(`${SERVER_URL}/${ResteurantCode}/delivery_places`, json, {
						'Content-Type': 'application/json',
					})
					.then(response => {
						if (response.status === 201 && response.statusText === 'Created') {
							setActive(true)
							setText('Dodaj')
							setPickUp(false)
							setComunicateText('Dodano! Możesz teraz dodać kolejną strefę')
						}
					})
					.catch(err => {})
			} else {
				setPickUp(false)
				setComunicateText('Odbiór osobisty już istnieje!')
			}
		} else {
			setComunicateText('Uzupełnij wszytkie pola')
		}
	}

	return (
		<>
			<form className='w-full '>
				<div className='grid grid-cols-5 gap-4 '>
					<div className='col-span-1  mb-6 md:mb-0 grow'>
						<label
							htmlFor='price'
							className='block text-sm font-normal text-gray'>
							Dystans
						</label>
						<div className='mt-1 relative rounded-md shadow-sm'>
							<input
								type='number'
								step="0.01"
								name='price'
								id='price'
								placeholder='do'
								value={distance}
								disabled={pickUp}
								onChange={e => {
									setDistance(e.target.value)
								}}
								className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md'
								aria-describedby='price-currency'
							/>
							<div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
								<span className='text-gray-500 sm:text-sm' id='price-currency'>
									KM
								</span>
							</div>
						</div>
					</div>

					<div className='col-span-1  mb-6 md:mb-0'>
						<label
							htmlFor='price'
							className='block text-sm font-normal text-gray'>
							Cena dostawy
						</label>
						<div className='mt-1 relative rounded-md shadow-sm'>
							<input
								type='number'
								step="0.01"
								id='basic-url'
								value={deliveryPrice}
								disabled={pickUp}
								placeholder='od'
								onChange={e => {
									setDeliveryPrice(e.target.value)
								}}
								className='focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 pl-7 pr-12 sm:text-sm border-borderGray rounded-md'
								aria-describedby='basic-addon3'
							/>
							<div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
								<span className='text-gray-500 sm:text-sm' id='price-currency'>
									zł
								</span>
							</div>
						</div>
					</div>
					<div className=' col-span-1 mb-6 md:mb-0'>
						<label htmlFor='price' className={`block text-sm ${activeDelivery ? 'text-gray-500' : 'text-gray'}`}>
							Darmowa dostawa
						</label>
						<div className='mt-1 relative rounded-md shadow-sm'>
							<input
								type='number'
								step="0.01"
								value={freeDelivery}
								name='price'
								disabled={activeDelivery}
								placeholder='od'
								onChange={e => {
									setFreeDelivery(e.target.value)
								}}
								id='basic-url'
								className={`focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 pl-7 pr-12 sm:text-sm border-borderGray rounded-md ${activeDelivery ? 'bg-gray-100 placeholder-gray-200' : ''}`}
								aria-describedby='price-currency'
							/>
							<div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
								<span className='text-gray-500 sm:text-sm' id='price-currency'>
									zł
								</span>
							</div>
						</div>
					</div>
					<div className='col-span-1 mt-4 pt-1 mb-6 md:mb-0 grow'>
						<button
							type='button'
							className=' focus:ring-indigo-500 focus:border-indigo-500 block  pl-7 pr-12 sm:text-sm border-borderGray mx-auto rounded-md inline-flex items-center  float-right px-4 py-2 border border-transparent text-basefont-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
							onClick={() => addDelivery()}
							disabled={!Active}>
							{Text}
						</button>
					</div>
				</div>
			</form>
			<p className={`mt-2 ${ComunicateText === 'Uzupełnij wszytkie pola' ? 'text-red-600' : ''}`}>{ComunicateText}</p>
			<div className={`flex justify-end ${showGoogleMaps ? 'mb-10' : 'mb-0'}`}>
						<Switch
							checked={showGoogleMaps}
							onChange={showGoogleMapsSection}
							className={`relative inline-flex h-13 w-26 border-1 border border-gray-100 rounded-sm cursor-pointer transition-colors focus:outline-none
						`}
							>
								<span
								className={`${showGoogleMaps ? 'bg-indigo' : 'bg-white'}
									inset-0 h-12 w-12 flex items-center justify-center transition-opacity rounded-sm
								`}
								aria-hidden="true"
								>
								<svg className="w-8 h-8" fill="none" stroke={`${showGoogleMaps ? 'white' : 'currentColor'}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path></svg>
								</span>
								<span
								className={`
									${showGoogleMaps ? 'bg-white' : 'bg-indigo'}
									inset-0 h-12 w-12 flex items-center justify-center transition-opacity rounded-sm
								`}
								aria-hidden="true"
								>
								<svg className="w-8 h-8" fill="none" stroke={`${showGoogleMaps ? 'currentColor' : 'white'}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M4 6h16M4 12h16M4 18h16"></path></svg>
								</span>
						</Switch>
						</div>
			{
				googleVisible ? 
				<div style= {{display: showGoogleMaps ? 'block' : 'none'}}>
					<GoogleMaps ranges={Delivery}/>
				</div>
				:
				<></>
			}

				
					<div style={{display: showGoogleMaps ? 'none' : 'block'}}>
						<p className='text-lg font-md font-bold mt-4'>Aktywne strefy dostaw</p>

						<ActiveZonesKM updateState={addedZone} delivery={Delivery} getDeliveryPlaces={getDeliveryPlaces} setChoosenDeliveryType={setChoosenDeliveryType} key={`zonesKM_${reactId}`}/>
					</div>

		</>
	)
}
