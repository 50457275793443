import React, { useState, useEffect } from "react";

export default function SetDayOpenHours({
  day,
  nr,
  close,
  open,
  funcOpen,
  allOpen,
  funcClose,
  allClose,
}) {
  const [Open, setOpen] = useState(open);
  const [Close, setClose] = useState(close);
  const [Disabled, setDisabled] = useState(null);
  const [Check, setCheck] = useState(null);
  useEffect(() => {
    if (open === "HH:MM" && close === "HH:MM") {
      setCheck(false);
      setDisabled(true);
    }else{
      setCheck(true);
      setDisabled(false);
    }
  }, []);

  const setChecked = () => {
    if (Check) {
      setCheck(false);
      setDisabled(true);
      setOpen("HH:MM");
      setClose("HH:MM");
      let x = allOpen;
      x[nr] = "HH:MM";
      funcOpen(x);
      let y = allClose;
      y[nr] = "HH:MM";
      funcClose(y);

 
    } else {
      setCheck(true);
      setDisabled(false);

      setOpen(open);
      setClose(close);
      let x = allOpen;
      x[nr] = open;
      funcOpen(x);

      let y = allClose;
      y[nr] = close;
      funcClose(y);
    }
  };


  const changeOpenH = (e) => {
    let x = allOpen;

    const patt = /[^(\d+)\:(\d+)]/g;
    let v = e.replace(patt, '');
    v = v.replace('..','.')
     
    // x[nr] = e;
    // funcOpen(x);
    // setOpen(e);

    x[nr] = v;
    funcOpen(x);
    setOpen(v);
    
  };
  const changeCloseH = (e) => {
    let x = allClose;
    x[nr] = e;
    funcClose(x);
    setClose(e);
  };
  return (
    <div className="flex justify-between items-center mb-3 w-full mx-0 ">
      <div className="align-items-center">
        {" "}
        <div class="custom-control custom-checkbox w-24 mr-11">
          <input
            type="checkbox"
            checked={Check}
            onChange={setChecked}
            maxLength={5}
            class="custom-control-input"
            id={day}
          />
          <label class="custom-control-label pl-3" for={day}>
            {day}
          </label>
        </div>
      </div>

      <div >
        <div className=" sm:flex ">
          <div className=" w-70 px-0 ">
            <div class="input-group  relative rounded-md shadow-sm ">
              <input
                type="text"
                class="form-control text-center"
                value={Open}
                disabled={Disabled}
                maxLength={5}
                id="basic-url"
                aria-describedby="basic-addon3"
                className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md'
                onChange={(e) => {
                  changeOpenH(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="col-2 px-0 text-center hidden sm:block">-</div>

          <div className="w-70 px-0">
            <div class="input-group  rounded-md shadow-sm ">
              <input
                type="text"
                class="form-control text-center"
                value={Close}
                disabled={Disabled}
                id="basic-url"
                aria-describedby="basic-addon3"
                className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md'
                onChange={(e) => {
                  changeCloseH(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
       </div>
    </div>
  );
}
