import React, { useState } from "react";

export default function Options({ id, func, func2, disabled }) {
  const [Price, setPrice] = useState({
    name: null,
    price: null,
  });

  const [ZL, setZL] = useState("");
  const [GR, setGR] = useState("");
  const ZLValid = (value) => {
    let v = value.replace(/\D/, "");
    setZL(v);
    sendPrice(1, v);
  };
  const GRValid = (value) => {
    let v = value.replace(/\D/, "");
    if (v.length <= 2) {
      setGR(v);
      sendPrice(2, v);
    }
  };
  const sendPrice = (x, value) => {
    if (x === 1) {
      let p = Price;
      p.price = value + "." + GR;
      setPrice(p);
      func(id, p);
    } else if (x === 2) {
      let p = Price;
      p.price = ZL + "." + value;
      setPrice(p);
      func(id, p);
    }
  };

  const sendname = (value) => {
    let p = Price;
    p.name = value;
    setPrice(p);
    func(id, p);
  };

  return (
    <div className="row  px-0 mx-0 pb-2 mb-1">
      <div className="col-7 px-2 mb-2">
        <div className="input-group">
          {" "}
          <input
            type="text"
            className="w-100 form-control"
            placeholder="dodatek"
            disabled={disabled}
            onChange={(e) => {
              sendname(e.target.value);
            }}
          />
        </div>{" "}
      </div>
      <div className=" col-4 pr-2 pl-0 mb-2">
        <div className="input-group w-50 float-left">
          {" "}
          <input
            type="text"
            className="w-100 form-control"
            placeholder="zł"
            value={ZL}
            onChange={(e) => {
              ZLValid(e.target.value);
            }}
          />{" "}
        </div>
        <div className="input-group w-50 float-left">
          {" "}
          <input
            type="text"
            className="w-100 form-control"
            placeholder="gr"
            value={GR}
            onChange={(e) => {
              GRValid(e.target.value);
            }}
          />{" "}
        </div>

      </div>
      <div className=" col-1 pr-2 pl-0 mb-2">
        <button
          type="button"
          class="btn btn-outline-danger border-0 "
          onClick={() =>
            func2(id)
          }
        >
          X
        </button>
      </div>
    </div>
  );
}
