import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import "../CSS/Loading.css";
export default function SMSCampainMenagment({
  e,
  all,
  nl,
  left,
  func1,
  func2,
  func3,
  sender,
}) {
  const URL = useSelector((e) => e.UserReducer.url);
  const Sender = useSelector((e) => e.UserReducer.sender);
  const RestaurantCode = useSelector(
    (e) => e.UserReducer.allData.restaurant_info.code
  );
  const [Active, setActive] = useState(true);
  const [Opt, setOpt] = useState("");
  const [Display, setDisplay] = useState("none");
  const [DisplayError, setDisplayError] = useState("none");
  const [Error, setError] = useState(false);

  const [DisplayLoading, setDisplayLoading] = useState("none");

  useEffect(() => {
    if (nl * Math.ceil(e.text.length / 160) <= left && e.status === "0") {
      setActive(false);
    } else if (Math.ceil(e.text.length / 160) >= left && e.status === "0") {
      setActive(true);
      setOpt("NoSMS");
    } else if (e.status === "1") {
      setActive(true);
      setOpt("Done");
    }
  }, [nl, left, e, all]);

  const finishCampaign = () => {
    setDisplayLoading("flex");
    //console.log(e);
    axios
      .get(`${URL}/sqlite/send_sms_campaign.php/?id=${e.id}&sender=${Sender}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          setDisplayLoading("none");
          setDisplay("flex");
          setTimeout(function () {
            func1();
            func2();
            func3();
          }, 1500);
        } else {
          setDisplayLoading("none");
          setDisplay("none");
          setDisplayError("flex");
          setError(true);
          setTimeout(function () {
            setDisplayError("none");
            setError(false);
            func1();
            func2();
            func3();
          }, 1500);
        }
      })

      .catch((err) => {
        //console.log(err.response);
        setDisplayLoading("none");
        setDisplay("none");
        setDisplayError("flex");
        setError(true);
        setTimeout(function () {
          setDisplayError("none");
          setError(false);
          func1();
          func2();
          func3();
        }, 1500);
      });
  };

  const deleteCampaign = () => {
    axios
      .get(
        `${URL}/sqlite/delete_sms_campaign.php/?id=${e.id}`,

        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          func3();
          func1();
          func2();
        }
      })

      .catch((err) => {
        //console.log(err);
      });
  };
  return (
    <div className="w-full  ">
      <div className="flex  border-bottom justify-between  mt-2 ">
        <div className="flex   border-bottom justify-between ">
          <div className="text-sm w-40 text-left  font-weight:300 d-flex  align-items-center">
            {e.name}
          </div>
          <div className=" text-sm w-24 text-left   font-weight:300 d-flex px-1 align-items-center">
            {Math.ceil(e.text.length / 160) * nl} SMS
          </div>
          <div className="text-sm w-64 text-left  font-weight:300 d-flex  align-items-center">
            {e.text}
          </div>
        </div>

        <div className="flex flex-col items-center lg:flex-row">
          {Opt === "Done" ? (
            <div
              class="alert aspect-auto h-10 w-56 mr-2 text-center lg:w-24  px-1 py-1 my-1 alert-success  text-sm leading-4 font-medium "
              role="alert"
            >
              Kampania zrealizowana
            </div>
          ) : (
            ""
          )}
          {Opt === "NoSMS" ? (
            <div
              class="alert aspect-auto h-10  px-1 mx-2 py-1 my-1 alert-danger w-56 text-center  lg:w-24  text-sm leading-4 font-medium overflow-hidden"
              role="alert"
            >
              Za mało SMS. Dokup SMS
            </div>
          ) : (
            ""
          )}

          <div
            style={{
              background: "green",
              display: Display,
            }}
            class="alert h-10 alert-success w-56 text-center mx-1 my-1 lg:w-24 px-1  justify-items-center  text-sm leading-4 font-medium "
          >
            Wysłano
          </div>
          <div
            style={{
              background: "white",
              display: DisplayLoading,
            }}
            className=" justify-content-center aspect-auto   Bold text-white text-left"
          >
            <div
              className="bodyLoad w-100"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                background: "white",
                display: DisplayLoading,
              }}
            >
              <div class="loading">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div>
          </div>
          {Error ? (
            <div
              style={{
                //position: "absolute",

                top: 0,
                left: 0,
                background: "red",
                display: DisplayError,
              }}
              className="alert w-56 text-center   alert-danger h-10  px-1 py-1 my-1 lg:w-24  text-sm leading-4 font-medium  Bold text-white"
            >
              Błąd! Spróbuj póżniej
            </div>
          ) : (
            <div className="flex flex-col justify-center gap-y-1 ml-2">
              <button
                type="button"
                class={
                  Active
                    ? `d-none  '`
                    : `d-inline    h-10 items-center px-1 my-1  border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'`
                }
                disabled={Active}
                onClick={() => finishCampaign()}
              >
                Zrealizuj kamapnię
              </button>

              <button
                type="button"
                className="inline-flex h-10 items-center justify-center  px-1 py-1 mb-1 my-1 text-sm leading-4 font-medium rounded-md shadow-sm hover:bg-lightIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => deleteCampaign()}
              >
                Usuń kamapnię
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
