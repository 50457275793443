import React, { useState, useEffect } from "react";
import axios from "axios";
import "../CSS/Loading.css";
import { SetshowModal } from "../store/UserReducer";
import { useDispatch, useSelector } from "react-redux";

import CircleCheck from "../img/circle-checked.svg";
import Circle from "../img/circle-x.svg";
import Discount from "../img/discount.svg";
import Icon from "../img/icon.svg";
import PinStart from "../img/pin-start.svg";
import Pin from "../img/pin.svg";
import Route from "../img/route.svg";
import { SetAllOrders, SetsellecTedOrder } from "../store/UserReducer";
export default function SingleOrder({
  element,
  stepOne,
  stepTwo,
  stepThree,
  setCurentProgress,
}) {
  const dispatch = useDispatch();
  const [Height, setHeight] = useState("");

  useEffect(() => {
    setHeight(document.getElementById("getHeight").clientHeight);
  }, []);

  const [OrderState, setOrderState] = useState("Order");

  const step1 = async () => {
    setOrderState("Loading");
    await stepOne(element);
    setOrderState("Order");
  };

  const step2 = async () => {
    setOrderState("Loading");
    await stepTwo(element);
    setOrderState("Order");
  };
  const step3 = async () => {
    setOrderState("Loading");
    await stepThree(element);
    setOrderState("Order");
  };

  return (
    <div className="px-1 w-100 mb-1 float-left">
      <div
        style={{
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
          height: "100%!important",
          border: "1px solid #dadee2",
          borderRadius: "5px",
        }}
        className={
          element.is_place
            ? "px-2 pointer py-2 row mx-0"
            : "px-2 pointer border border-info py-2 row mx-0"
        }
        id="getHeight"
      >
        <div
          className="w-full"
          onClick={() => {
            console.log(element)
            SetsellecTedOrder(element, dispatch);
            setCurentProgress(element.progress);
            SetshowModal(true, dispatch);
          }}
        >
          <div className="col-12 px-0 fs1 Font15px">
            <div class="span3">
              {element.progress === "2" ? (
                <div
                  className="ColorIcon d-flex justify-content-center align-items-center mr-1"
                  style={{
                    backgroundColor: "#f2994a",
                  }}
                >
                  <i class="demo-icon icon-clock text-white mx-0">&#xe802;</i>
                </div>
              ) : element.progress === "3" ? (
                <div
                  className="ColorIcon d-flex justify-content-center align-items-center mr-1"
                  style={{
                    backgroundColor: "#56ccf2",
                  }}
                >
                  <i
                    class="demo-icon icon-reserve text-white "
                    style={{
                      backgroundColor: "#56ccf2",
                    }}
                  >
                    &#xe806;
                  </i>
                </div>
              ) : element.progress === "4" ? (
                <div
                  className="ColorIcon d-flex justify-content-center align-items-center mr-1"
                  style={{
                    backgroundColor: "#27ae60",
                  }}
                >
                  <i
                    class="demo-icon icon-box text-white"
                    style={{
                      backgroundColor: "#27ae60",
                    }}
                  >
                    &#xe800;
                  </i>
                </div>
              ) : element.progress === "5" ? (
                <div
                  className="ColorIcon d-flex justify-content-center align-items-center mr-1"
                  style={{
                    backgroundColor: "#eb5757",
                  }}
                >
                  <i
                    class="demo-icon icon-clipboard-tick text-white"
                    style={{
                      backgroundColor: "#eb5757",
                    }}
                  >
                    &#xe801;
                  </i>
                </div>
              ) : null}
              {element.is_internal === true && element.is_place === false ? (
                <i class="demo-icon icon-gps mr-1">&#xe804;</i>
              ) : element.is_internal === false &&
                element.is_place === false ? (
                <i class="demo-icon icon-location mr-1">&#xe805;</i>
              ) : element.is_internal === false && element.is_place === true ? (
                <i class="demo-icon icon-routing mr-1">&#xe807;</i>
              ) : (
                ""
              )}
              {element.payment_type === "1" ? (
                <i class="demo-icon icon-tick-square mr-1">&#xe808;</i>
              ) : element.payment_type === "2" ? (
                <i class="demo-icon icon-close-square mr-1">&#xe803;</i>
              ) : (
                ""
              )}

              {element.promotion_code !== "" ? (
                <i class="demo-icon icon-verify mr-5">&#xe809;</i>
              ) : (
                ""
              )}
              {element.is_place === false ? (
                <span class="i-name mr-5">Stół: 55</span>
              ) : (
                ""
              )}

              <span class="i-name float-right text-xs Lite">
                {" "}
                {element.ordered_time + ", " + element.ordered_date}
              </span>
            </div>
          </div>
          <div className="col-12 px-0 py-1 ">
            {element.meal_options.map((element, index) => {
              if (element.additions.length === 0) {
                return (
                  <p className="mb-1">
                    <span className="text-sm Bold"> {element.name} </span>{" "}
                  </p>
                );
              } else if (element.additions.length > 0) {
                let additions = "";
                element.additions.map((elemant) => {
                  additions =
                    additions +
                    `<span class="text-sm Lite"> ${elemant.name}</span>`;
                });

                return (
                  <>
                    <p className="mb-0">
                      <span className="text-sm Bold"> {element.name}</span>
                    </p>
                    <p
                      className="mb-0 pl-2 text-sm Lite"
                      dangerouslySetInnerHTML={{ __html: additions }}
                    ></p>
                    {/* <p className=" Thin Font10px ml-3 mb-2"> + Dodatki (kliknij aby zoabczyć)</p>*/}
                  </>
                );
              }
            })}
          </div>

          <div className="col-12 px-0 fs1 border-top py-2">
            <p className="mb-0">
              <span
                className="mr-3 text-sm "
                style={{
                  color: "#6c757d",
                }}
              >
                NR: {element.id}
              </span>
              <span className="Bold float-right text-sm">
                {element.to_pay} zł
              </span>
            </p>
          </div>
        </div>

        {element.progress === "2" && (
          <button
            onClick={() => {
              step1();
            }}
            disabled={OrderState === "Loading"}
            type="button"
            class="disabled:bg-blue-500 items-center rounded border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-center w-full"
          >
            {OrderState === "Loading" ? (
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <>
                <span>Przygotowywane</span>
              </>
            )}
          </button>
        )}

        {element.progress === "3" && (
          <button
            onClick={() => step2()}
            disabled={OrderState === "Loading"}
            type="button"
            class="disabled:bg-green-500 items-center rounded border border-transparent bg-green-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 text-center w-full"
          >
            {OrderState === "Loading" ? (
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <>
                <span>Gotowe</span>
              </>
            )}
          </button>
        )}

        {element.progress === "4" && (
          <button
            onClick={() => step3()}
            disabled={OrderState === "Loading"}
            type="button"
            class="disabled:bg-red-500 items-center rounded border border-transparent bg-red-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 text-center w-full"
          >
            {OrderState === "Loading" ? (
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <>
                <span>Zakończ</span>
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
}
