import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { useSelector } from "react-redux";
export default function EmailCampainMenagment({ e, func }) {
  const URL = useSelector((e) => e.UserReducer.url);
  const [Display, setDisplay] = useState("none");
  const send = (id) => {
    axios
      .get(
        `${URL}/notifications/send_notification.php/?notification_id=${e.id}`,
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.message_id !== undefined && response.status === 200) {
          setDisplay("flex");
          setTimeout(function () {
            func();
            setDisplay("none");
          }, 1500);
        }
      })

      .catch((err) => {
        console.log(err.response);
      });
  };
  const deleteCampain = (id) => {
    axios
      .get(
        `${URL}/notifications/delete_notification.php/?notification_id=${id}`,
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        console.log(response);
        func();
      })

      .catch((err) => {
        //  console.log(err.response);
      });
  };
  return (
    <div>
      <div className="w-full ">
        <div className="flex  border-bottom justify-between  mt-2 ">
          <div className="flex   border-bottom justify-between align-items-center ">
            <div className="text-sm w-40 text-left mx-1  font-weight:300 d-flex  align-items-center">
              {e.name}
            </div>

            <div className=" text-sm w-24 text-left mx-1  font-weight:300 d-flex px-1 align-items-center">
              {e.title}
            </div>
            <div className="text-sm w-44 text-left mx-1 font-weight:300 d-flex  align-items-center">
              {ReactHtmlParser(e.body)}
            </div>
            <div className="  mb-2 aspect-auto border-bottom w-44 ">
              <img src={e.image} alt="" className="" />
            </div>
          </div>

          <div className="flex flex-col items-center lg:flex-row">
            <div
              style={{
                background: "green",
                display: Display,
              }}
              class="alert h-10 alert-success  text-center mx-1 my-1 lg:w-24 px-1  justify-items-center  text-sm leading-4 font-medium "
            >
              Wysłano
            </div>

            {e.sent === "0" ? (
              <div class="flex flex-col justify-items-end ">
                <button
                  type="button"
                  class=" mb-2 w-24 border-bottom d-inline    h-10 items-center px-1 my-1  border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => send(e)}
                >
                  Wyślij
                </button>
                <button
                  type="button"
                  class=" h-10 w-24 mb-2 border-bottom text-sm leading-4 font-medium rounded-md shadow-sm hover:bg-lightIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                  onClick={() => deleteCampain(e.id)}
                >
                  Usuń
                </button>
              </div>
            ) : (
              <div class="flex flex-col justify-items-end ">
                <div
                  class="alert alert-success w-24 border-bottom px-1 mb-2 "
                  role="alert"
                >
                  Kampania zrealizowana
                </div>

                <button
                  type="button"
                  class="h-10 w-24 mb-2 px-2 border-bottom text-sm leading-4 font-medium rounded-md shadow-sm hover:bg-lightIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => deleteCampain(e.id)}
                >
                  Usuń kampanię
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
