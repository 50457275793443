import React from "react";
import Header from "./Header";

import EditSelectMeal from "./EditSelectedMeal";
import { useSelector } from "react-redux";
export default function EditMeal({ match }) {
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <>
            <div className="row mx-0 px-0">
              <div className="col-12 my-5 text-center">
                <h1 className="upper Bold">Edytujesz menu</h1>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-4 my-3 "></div>
              <div className="col-md-8 my-3 ">
                <EditSelectMeal
                  category_id={match.params.categoryID}
                  meal_base_id={match.params.id}
                />
              </div>
            </div>
          </>
        </div>{" "}
      </div>
    </>
  );
}
