import React, { useState, useEffect } from "react";
import axios from "axios";
import NavNew from "./NavNew";
import { useDispatch, useSelector } from "react-redux";
import { SetSMSLeft, SetpricePerSMS, Setsender } from "../store/UserReducer";
import round from "../Functions/round";
import sha256 from "../Functions/sha256";
import SMSCampainMenagment from "./SMSCampainMenagment";
import HeaderNew from "../Components/HeaderNew";
import SettingsNavNew from "./TailwindComponents/SettingsNavNew/SettingsNavNew";
const SMS = () => {
  const DUMMY_COMPAIN = {
    name: "superowa kampania1 ",

    status: "1",
    text: "treść wi",
    id: "4",
  };
  const DUMMY_COMPAIN2 = {
    name: "superowa kampania2 ",

    status: "0",
    text: "treść wi treść witreść witreść witreść witreść wi treść witreść witreść witreść witreść wi treść witreść witreść witreść wi",
    id: "4",
  };

  const DUMMY_COMPAIN3 = {
    name: "superowa kampania3 z okazj nowego roku  ",

    status: "2",
    text: "zsgdrhtfjygukhlij;oh lgukfjyd htsgrfeaegsrhtjdfykulgkftjdtrhsgf",
    id: "4",
  };

  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  const URL = useSelector((e) => e.UserReducer.url);
  const IS_SMS_ACTIVE = useSelector((e) => e.UserReducer.is_sms_active);
  const SMSLeft = useSelector((e) => e.UserReducer.SMSLeft);
  const PricePerSMS = useSelector((e) => e.UserReducer.pricePerSMS);
  const Sender = useSelector((e) => e.UserReducer.sender);
  const [Active, setActive] = useState(true);

  const [ComunicateText, setComunicateText] = useState("");
  const [Display, setDisplay] = useState("none");
  const [NumbersLength, setNumbersLength] = useState(100);
  const [SMSTXT, setSMSTXT] = useState("");
  const [Price, setPrice] = useState(0.15);
  const [AllNumbers, setAllNumbers] = useState("");
  const [Name, setName] = useState("");
  const RestaurantCode = useSelector(
    (e) => e.UserReducer.allData.restaurant_info.code
  );
  const RestaurantName = useSelector(
    (e) => e.UserReducer.allData.editable_texts.restaurant_name
  );
  const RestaurantEmail = useSelector(
    (e) => e.UserReducer.allData.editable_texts.restaurant_email
  );
  const [ShopID, setShopID] = useState("705218");
  const [PIN, setPIN] = useState("qWgJDx6ulKzbPZImX7cQqCpJR5RPEuxW");
  const [CHK, setCHK] = useState(null);
  const BackURL = window.location.href;
  const Currency = "PLN";
  const Lang = "pl";
  const ChannelGroups = "K,T,M,I";
  const Type = "0";
  const IgnoreLastPaymentChannel = 0;
  const [Amount, setAmount] = useState(0);
  const [AmountShow, setAmountShow] = useState(0);
  const [Control, setControl] = useState(null);
  const [AllCampaigns, setAllCampaigns] = useState([]);
  const [Package, setPackage] = useState(0);
  const [Description, setDescription] = useState("");
  const [URLC, setURLC] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    get_costumer_data();
    get_sms_campain();
  }, []);
  const shaCreator = (ascii) => {
    let chk = null;
    //console.log(ascii);
    if ((chk = sha256(ascii))) {
      //console.log(chk);
      return chk;
    }
    //console.log(chk);
  };
  const sms_left = () => {
    axios
      .get(`${SERVER_URL}/${RestaurantCode}/sms_left `, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        SetSMSLeft(response.data.sms_left, dispatch);
        SetpricePerSMS(response.data.sms_price, dispatch);
        Setsender(response.data.sender, dispatch);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const get_costumer_data = () => {
    axios
      .get(`${URL}/sqlite/get_marketing_data.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        setNumbersLength(response.data.length);
        setAllNumbers(response.data);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const get_sms_campain = () => {
    axios
      .get(`${URL}/sqlite/get_sms_campaigns.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        setAllCampaigns([]);
        setAllCampaigns(response.data);
        //console.log(response);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };

  const showSendSMS = () => {
    setDisplay("block");
  };
  const cancelSendEmail = () => {
    setDisplay("none");
  };
  const random = () => {
    let cod =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    ////console.log("Wyrandowany kod:" + cod);
    return cod;
  };
  const createSMSCampaing = () => {
    if (SMSTXT !== "" && Name !== "") {
      axios
        .post(
          `${URL}/sqlite/create_sms_campaign.php`,
          {
            name: Name,
            text: SMSTXT,
            cost: Amount.toFixed(2),
            recivers_number: AllNumbers,
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          if (response.status === 200) {
            //console.log(response);
            get_sms_campain();
            setDisplay("none");
            setComunicateText("Utorzono nową kampanię SMS");
            setName("");
            setSMSTXT("");
          } else {
            setDisplay("none");
            setComunicateText("Coś poszło nie tak, spróbuj ponownie");
          }
        })

        .catch((err) => {
          setDisplay("none");
          setComunicateText("Coś poszło nie tak, spróbuj ponownie");
          //console.log(err.response);
        });
    }
    {
      setDisplay("none");
      setComunicateText("Wpisz treść SMS i nazwę kampani");
    }
  };
  String.prototype.escapeDiacritics = function () {
    return this.replace(/ą/g, "a")
      .replace(/Ą/g, "A")
      .replace(/ć/g, "c")
      .replace(/Ć/g, "C")
      .replace(/ę/g, "e")
      .replace(/Ę/g, "E")
      .replace(/ł/g, "l")
      .replace(/Ł/g, "L")
      .replace(/ń/g, "n")
      .replace(/Ń/g, "N")
      .replace(/ó/g, "o")
      .replace(/Ó/g, "O")
      .replace(/ś/g, "s")
      .replace(/Ś/g, "S")
      .replace(/ż/g, "z")
      .replace(/Ż/g, "Z")
      .replace(/ź/g, "z")
      .replace(/Ź/g, "Z");
  };
  const [Quantity, setQuantity] = useState();
  const setPayment = async (value) => {
    setTXT("");
    let amount = 0;
    if (value !== "Wybierz pakiet SMS") {
      amount = round(value * PricePerSMS * 1.23);
      setAmountShow(amount);
      amount = round(amount / 0.985);
      setAmount(amount);
      setQuantity(value);
    } else {
      setAmount(0);
      setAmountShow(0);
      amount = 0;
      setQuantity(0);
    }
    let control = random();
    setControl(control);
    let description = RestaurantCode + "-" + RestaurantName.escapeDiacritics();
    setDescription(description);
    let urlc = `${SERVER_URL}/${RestaurantCode}/sms_payment_confirm`;
    setURLC(urlc);
    let chk = await shaCreator(
      PIN +
        Lang +
        ShopID +
        amount.toFixed(2) +
        Currency +
        description +
        control +
        ChannelGroups +
        BackURL +
        Type +
        urlc +
        RestaurantEmail +
        IgnoreLastPaymentChannel
    );
    //console.log(chk);
    setCHK(chk);
  };
  const [TXT, setTXT] = useState("");
  const [ButtonActive, setButtonActive] = useState(false);
  const submitPay = () => {
    if (Amount !== 0 && Amount !== "0") {
      setButtonActive(true);
      let json = {
        sms_quantity: Quantity,
        payment_control: Control,
        to_pay: Amount,
      };
      axios
        .post(`${SERVER_URL}/${RestaurantCode}/sms_order `, json, {
          "Content-Type": "application/json",
        })
        .then((response) => {
          console.log(response);
          if (
            response.status === 200 &&
            response.statusText === "OK" &&
            JSON.parse(response.data).data.token !== undefined
          ) {
            setButtonActive(false);
            window.location.replace(
              `https://secure.przelewy24.pl/trnRequest/${
                JSON.parse(response.data).data.token
              }`
            );
          }
        })

        .catch((err) => {
          setButtonActive(false);
          console.log(err.response);
        });
    } else {
      setTXT("Wybierz pakiet");
    }
  };
  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <SettingsNavNew
            tabs={[
              { name: "Kupony rabatowe", path: "/campaigns/cupons" },
              { name: "Kampanie e-mail", path: "/campaigns/email" },
              { name: "Kampanie SMS", path: "/campaigns/sms" },
              {
                name: "Apilkacja mobilna ",
                path: "/campaigns/pushnotification",
              },
              { name: "Baza klientów", path: "/campaigns/customermanagement" },
            ]}
            title="Kampanie promocyjne"
          />
          <NavNew />

          <div className="container-fluid">
            <div
              className="container-fluid display  px-0 position-relative "
              style={{ background: "white", minHeight: window.innerHeight }}
            >
              <div className="max-w-7xl mx-auto ">
                <div className="pl-5 pt-1 pr-5  ">
                  <div className=" mt-2">
                    <p className="text-darkgray font-semibold text-lg pt-3 font-weight: 600 font-i">
                      Nowa kampania SMS
                    </p>
                  </div>

                  <label className=" font-normal pb-1 ">Nazwa kampanii</label>
                  <div class="mb-4 relative rounded-md">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder="Nazwa kampani "
                      value={Name}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  <label className=" font-normal pb-1 ">Treść SMS</label>
                  <div class="mb-3 relative rounded-md">
                    <textarea
                      placeholder="Treść SMS"
                      value={SMSTXT}
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      aria-label="With textarea"
                      onChange={(e) => {
                        if (e.target.value.length <= 612) {
                          setSMSTXT(e.target.value.escapeDiacritics());
                        }
                      }}
                    ></textarea>
                  </div>
                  <div className="flex  ">
                    <p className=" pr-5">Znaki: {SMSTXT.length}/612</p>{" "}
                    <p>Ilość SMS : {Math.ceil(SMSTXT.length / 160)}</p>{" "}
                  </div>
                  <div className="flex items-center justify-between">
                    <div className=" w-75">
                      <div class="mb-3 relative rounded-md ">
                        <select
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                          onChange={(e) => {
                            setPayment(e.target.value);
                          }}
                        >
                          <option selected>Wybierz pakiet SMS</option>

                          <option value="1000">1000 SMS</option>
                          <option value="2000">2000 SMS</option>
                          <option value="3000">3000 SMS</option>
                          <option value="4000">4000 SMS</option>
                          <option value="5000">5000 SMS</option>
                          <option value="6000">6000 SMS</option>
                          <option value="7000">7000 SMS</option>
                          <option value="8000">8000 SMS</option>
                          <option value="9000">9000 SMS</option>
                          <option value="10000">10000 SMS</option>
                        </select>
                      </div>
                    </div>

                    {IS_SMS_ACTIVE === true ? (
                      <button
                        type="button"
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled={ButtonActive}
                        onClick={() => {
                          submitPay();
                        }}
                      >
                        Kup pakiet
                      </button>
                    ) : (
                      <p className="h6">
                        Aby aktywowac zakup SMS skontaktuj się z dostawcą
                        systemu FlexMenu
                      </p>
                    )}
                  </div>
                  <p>{TXT}</p>
                  <p className="mb-0">
                    <a href={URL + "/documents/sms.pdf"}>Regulamin SMS</a>
                  </p>
                  <p className="mb-0">
                    <a href={URL + "/documents/agreement.pdf"}>Umowa</a>
                  </p>
                  <p>{TXT}</p>

                  <p>
                    Pozostało do wykorzystania{" "}
                    <span className="Bold">{SMSLeft} </span> SMS'ów
                  </p>
                  <p>
                    Ilość numerów w bazie :{" "}
                    <span className="Bold">{NumbersLength} </span>
                  </p>

                  <div className="flex justify-between">
                    <div></div>
                    <button
                      type="button"
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      disabled={!Active}
                      onClick={() => createSMSCampaing()}
                    >
                      Swórz kampanię
                    </button>
                  </div>

                  <div className=" mt-4">
                    <p className="text-darkgray font-semibold text-lg pt-3 font-weight: 600 font-i">
                      Istaniejące kampanie SMS
                    </p>
                  </div>

                  <div>
                    <div className="flex border-bottom ">
                      <div className="  font-bold w-34 text-left  text-base mr-7 pt-3  font-weight:400 d-flex justify-content-center align-items-center">
                         Nazwa kampanii
                      </div>
                      <div className=" font-bold w-24 text-left  text-base   pt-3 font-weight:400 px-2 d-flex justify-content-center align-items-center">
                        Ilość SMS
                      </div>
                      <div className="font-bold w-64 text-left text-base  pt-3 font-weight:400 d-flex px-2 align-items-center">
                        Treść SMS
                      </div>
                    </div>

                    <div className="do usuniącia ">
                      <SMSCampainMenagment
                        e={DUMMY_COMPAIN}
                        all={AllNumbers}
                        left={SMSLeft}
                        nl={NumbersLength}
                        func1={sms_left}
                        func2={get_costumer_data}
                        func3={get_sms_campain}
                        sender={Sender}
                      />
                      <SMSCampainMenagment
                        e={DUMMY_COMPAIN2}
                        all={AllNumbers}
                        left={SMSLeft}
                        nl={NumbersLength}
                        func1={sms_left}
                        func2={get_costumer_data}
                        func3={get_sms_campain}
                        sender={Sender}
                      />
                      <SMSCampainMenagment
                        e={DUMMY_COMPAIN3}
                        all={AllNumbers}
                        left={SMSLeft}
                        nl={NumbersLength}
                        func1={sms_left}
                        func2={get_costumer_data}
                        func3={get_sms_campain}
                        sender={Sender}
                      />

                      {AllCampaigns.map((element) => (
                        <SMSCampainMenagment
                          e={element}
                          all={AllNumbers}
                          left={SMSLeft}
                          nl={NumbersLength}
                          func1={sms_left}
                          func2={get_costumer_data}
                          func3={get_sms_campain}
                          sender={Sender}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
//
export default SMS;
