import React, { useEffect, useState } from "react";

export default function Addition({ e, func }) {
  const [Hide, setHide] = useState("none");
  const hide = () => {
    if (Hide === "none") {
      setHide("block");
    } else {
      setHide("none");
    }
  };

  return (
    <>
      <div className="row mx-0 px-2 my-2">
        <div className="col-2 my-1 mx-0 px-0 text-left d-flex align-items-center">
          <button
            type="button"
            class="btn btn-danger px-1 py-0"
            onClick={() => func(e.id)}
          >
            Usuń
          </button>
        </div>

        <div className="col-4 my-1 d-flex align-items-center Bold upper">
          {e.name}
        </div>

      {/*   <div className="col-2 my-1 d-flex align-items-center">
          <p className="mb-0">
            {" "}
            <button
              class="btn btn-primary px-2 py-0"
              type="button"
              onClick={() => hide()}
            >
              Rozwiń
            </button>
          </p>
        </div>*/}
      </div>
     {/* <div class="row mx-0 px-2" style={{ display: Hide }}>
        <div class="col mx-0 px-0">
          <div class="card card-body py-2 px-1">test test</div>
        </div>
      </div>*/}
    </>
  );
}
