import { svgOverlay } from "leaflet";
import React, { useState } from "react";
import { useAccordionToggle } from "react-bootstrap";
export default function CustomToggle({ children, eventKey }) {
  const [Text, setText] = useState("Pokaż dania");
  const [SvgImg, setSvgImg] = useState(
    "M14.5999 1.45837L9.16657 6.89171C8.5249 7.53338 7.4749 7.53338 6.83324 6.89171L1.3999 1.45837"
  );

  const change = () => {
    if (Text === "Pokaż dania") {
      setText("Ukryj dania");
      setSvgImg(
        "M14.5999 7.54166L9.16657 2.10833C8.5249 1.46666 7.4749 1.46666 6.83324 2.10833L1.3999 7.54166"
      );
    } else if (Text === "Ukryj dania") {
      setText("Pokaż dania");

      setSvgImg(
        "M14.5999 1.45837L9.16657 6.89171C8.5249 7.53338 7.4749 7.53338 6.83324 6.89171L1.3999 1.45837"
      );
    }
  };
  const decoratedOnClick = useAccordionToggle(eventKey, () => change());
  return (
    <button
      type="button"
      className="   Lite btn     py-1"
      onClick={decoratedOnClick}
    >
      <svg
        width="16"
        height="9"
        viewBox="0 0 16 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={SvgImg}
          stroke="#292D32"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
}
