import React, { useEffect, useState } from "react";

import FileBase64 from "react-file-base64";
import axios from "axios";
import NavNew from "./NavNew";
import { useSelector } from "react-redux";
import HeaderNew from "../Components/HeaderNew";
import SetDayOpenHours from "./SetDayOpenHours";
import SettingsNavNew from "./TailwindComponents/SettingsNavNew/SettingsNavNew";

export default function CompanyInfo() {
  const URL = useSelector((e) => e.UserReducer.url);
  const UserInfo = useSelector((e) => e.UserReducer.allData);

  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Zapisz zmiany");
  const [ComunicateText, setComunicateText] = useState("");

  const [CompanyName, setCompanyName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phoneday, setPhoneday] = useState("");

  const [City, setCity] = useState("");
  const [Street, setStreet] = useState("");
  const [NIP, setNIP] = useState("");
  const [FileText, setFileText] = useState(null);
  const [FileName, setFileName] = useState(null);

  const File = (files) => {
    setFileText(files.base64);
    setFileName(files.name.replace(/\s+/g, ""));
  };

  const [touched, setTouched] = useState(false);
  const [touchedMarketing,setTouchedMarketing] = useState(false);
  const [touchedHost,setTouchedHost] = useState(false);
  const [EmailValid,setEmailValid] = useState(false)
  const [EmailHostValid,setEmailHostValid] = useState(false)
  const [EmailMarketingValid,setEmailMarketingValid] = useState(false)
  const [ClosingHour, setClosingHour] = useState(null);
  const [OpeningHour, setOpeningHour] = useState(null);
  const [Marketing_email, setMarketing_email] = useState("");
  const [Marketing_email_pass, setMarketing_email_pass] = useState("");
  const [Marketin_email_domain, setMarketin_email_domain] = useState("");
  const [Marketing_email_signature, setMarketing_email_signature] =
    useState("");
  const [FCM_Server_key, setFCM_Server_key] = useState("");
  const [NewPass, setNewPass] = useState("");
  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setCompanyName(UserInfo.editable_texts.restaurant_name);
      setEmail(UserInfo.editable_texts.restaurant_email);
      setPhoneday(UserInfo.editable_texts.restaurant_phone);

      setCity(UserInfo.editable_texts.restaurant_city);
      setStreet(UserInfo.editable_texts.restauransetFCM_Server_keyt_street);
      setNIP(UserInfo.editable_texts.restaurant_nip);
      setMarketing_email(UserInfo.editable_texts.marketing_email);
      setMarketing_email_pass(UserInfo.editable_texts.marketing_email_pass);
      setMarketin_email_domain(UserInfo.editable_texts.marketin_email_domain);
      setMarketing_email_signature(
        UserInfo.editable_texts.marketing_email_signature
      );

      setFCM_Server_key(UserInfo.editable_texts.FCM_Server_key);
    }
    if (UserInfo.closing_hour !== undefined) {
      setClosingHour(UserInfo.closing_hour);
    }
    if (UserInfo.opening_hour !== undefined) {
      setOpeningHour(UserInfo.opening_hour);
    }
  }, [UserInfo]);

  const setWebsiteData = () => {
    //console.log(ClosingHour);
    //console.log(OpeningHour);
    let newPass = "";
    if (NewPass !== "") {
      newPass = NewPass;
    } else {
      newPass = Marketing_email_pass;
    }
    let json = {};
    if (FileName !== null) {
      json = {
        editable_texts: {
          restaurant_name: CompanyName,
          restaurant_email: Email,
          restaurant_phone: Phoneday,

          restaurant_city: City,
          restaurant_street: Street,
          restaurant_nip: NIP,
          closing_hour: ClosingHour,
          opening_hour: OpeningHour,

          marketing_email: Marketing_email,
          marketing_email_pass: newPass,
          marketin_email_domain: Marketin_email_domain,
          marketing_email_signature: Marketing_email_signature,
        },
        img: {
          logo: {
            photo_url: "/img/restaurant/" + FileName,
            encoded_photo: FileText,
          },
        },
        closing_hour: ClosingHour,
        opening_hour: OpeningHour,
      };
    } else if (FileName === null) {
      json = {
        editable_texts: {
          restaurant_name: CompanyName,
          restaurant_email: Email,
          restaurant_phone: Phoneday,

          restaurant_city: City,
          restaurant_street: Street,
          restaurant_nip: NIP,

          marketing_email: Marketing_email,
          marketing_email_pass: newPass,
          marketin_email_domain: Marketin_email_domain,
          marketing_email_signature: Marketing_email_signature,
        },
        closing_hour: ClosingHour,
        opening_hour: OpeningHour,
      };
    }
    let json2 = {
      closing_hour: ClosingHour,
      opening_hour: OpeningHour,
    };
    //console.log(json);
    setActive(false);
    setText("Czekaj");
    axios
      .post(`${URL}/set_website_data.php`, json, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);

        if (response.status === 200) {
          axios
            .post(`${URL}/set_open_close.php`, json2, {
              "Content-Type": "application/json",
            })
            .then((response) => {
              //console.log(response);

              if (response.status === 200) {
                setActive(true);
                setText("Zapisz zmiany");
                setComunicateText("Zapisano zmiany");
              }
            })
            .catch((err) => {
              //console.log(err.response);
              //console.log(err.request);
              //console.log("Error", err.message);
              setComunicateText("Cos poszło nie tak");
            });
        }
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        setComunicateText("Cos poszło nie tak");
      });
  };
  return (
    <>
      <HeaderNew />

      <div id="content-wrapper" className="   flex-column flex bg-white  ">
        <div id="content">
          <SettingsNavNew
            tabs={[
              { name: "Strefy dostaw", path: "/customize/delivery" },
              { name: "Dane firmy", path: "/customize/companyinfo" },
              { name: "Alerty", path: "/customize/alerts" },
              { name: "Wygląd", path: "/customize/style" },
              { name: "Dodatkowe teksty", path: "/customize/texts" },
              { name: "Legenda", path: "/customize/icons" },
            ]}
            title="Ustawienia"
          />
          <NavNew />

          <div className="w-640px   h-full ">
            <div className="pl-5  pr-5  ">
              <p className="text-darkgray font-semibold text-lg  font-i pt-4 ">
                Ogólne
              </p>

              <label className=" font-normal pb-1 ">Nazwa firmy</label>
              <div class=" mb-4 relative rounded-md ">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={CompanyName}
                  id="basic-url"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </div>

              <label className=" font-normal pb-1 ">Miejscowość</label>
              <div class="mb-4 relative rounded-md ">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={City}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </div>

              <label className=" font-normal pb-1 ">Ulica i numer</label>
              <div class=" mb-4 relative rounded-md ">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={Street}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  onChange={(e) => {
                    setStreet(e.target.value);
                  }}
                />
              </div>
              <label className=" font-normal pb-1 ">NIP</label>
              <div class="mb-4 relative rounded-md">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={NIP}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  onChange={(e) => {
                    setNIP(e.target.value);
                  }}
                />
              </div>

              <div class="input-group mb-10 mt-2   opacity-0 z-10 ">
                <FileBase64
                  multiple={false}
                  onDone={File.bind()}
                  className=""
                />
              </div>
              <div className="flex items-start relative -top-16 left-0 ">
                <button className="mr-2 border-borderGray border-1 px-2 rounded-md w-106px h-38px text-sm  font-medium ">
                  {" "}
                  Dodaj logo
                </button>
                <p>JPG lub PNG max 2mb</p>
              </div>

              <div className="flex justify-between  pb-3">
                <p className="text-darkgray font-semibold text-lg  w-600">
                  Godziny otwarcia
                </p>
              </div>

              {ClosingHour !== null && OpeningHour !== null ? (
                <>
                  <div className=" w-540">
                    <SetDayOpenHours
                      day="Poniedziałek"
                      nr={1}
                      open={OpeningHour[1]}
                      close={ClosingHour[1]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                    <SetDayOpenHours
                      day="Wtorek"
                      nr={2}
                      open={OpeningHour[2]}
                      close={ClosingHour[2]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                    <SetDayOpenHours
                      day="Środa"
                      nr={3}
                      open={OpeningHour[3]}
                      close={ClosingHour[3]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                    <SetDayOpenHours
                      day="Czwartek"
                      nr={4}
                      open={OpeningHour[4]}
                      close={ClosingHour[4]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                    <SetDayOpenHours
                      day="Piątek"
                      nr={5}
                      open={OpeningHour[5]}
                      close={ClosingHour[5]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                    <SetDayOpenHours
                      day="Sobota"
                      nr={6}
                      open={OpeningHour[6]}
                      close={ClosingHour[6]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                    <SetDayOpenHours
                      day="Niedziela"
                      nr={0}
                      open={OpeningHour[0]}
                      close={ClosingHour[0]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="flex justify-between mt-40px mb-16px">
                <p className="text-darkgray font-semibold text-lg pt-3'">
                  Dane kontaktowe
                </p>
              </div>
              <label className=" font-normal pb-1 ">Telefon</label>
              <div class="input-group mb-4 rounded-md ">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={Phoneday}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  onChange={(e) => {
                    setPhoneday(e.target.value);
                  }}
                />
              </div>

              <label className=" font-normal pb-1 ">Adres e-mail</label>
              {!EmailValid && touched &&<label className=" font-normal pb-1 text-red-600 ml-4"> Proszę podać poprawny email </label>}
              <div class="input-group mb-4 rounded-md ">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={Email}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  onChange={(e) => {
                   
                    setEmailValid(e.target.value.match(/\S+@\S+\.\S+/))
                    setEmail(e.target.value);
                  }}
                  onBlur={() => setTouched(true)}
                />
              </div>

              <div className="flex justify-between mt-2">
                <p className="text-darkgray font-semibold text-lg pt-3'">
                  Marketingowe konto e-mail
                </p>
              </div>
              <label className=" font-normal pb-1 ">Email</label>
              {!EmailMarketingValid && touchedMarketing &&<label className=" font-normal pb-1 text-red-600 ml-4"> Proszę podać poprawny email </label>}
              <div class="input-group mb-4 rounded-md ">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={Marketing_email}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  onChange={(e) => {
                    setEmailMarketingValid(e.target.value.match(/\S+@\S+\.\S+/))
                    setMarketing_email(e.target.value);
                  }}
                  onBlur={() => setTouchedMarketing(true)}
                />
              </div>
              <label className=" font-normal pb-1 ">Podpis nadawcy</label>
              <div class="input-group mb-4 rounded-md">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={Marketing_email_signature}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  onChange={(e) => {
                    setMarketing_email_signature(e.target.value);
                  }}
                />
              </div>

              <label className=" font-normal pb-1 ">Hasło</label>
              <div class="input-group mb-4 rounded-md ">
                <input
                  type="text"
                  class="form-control"
                  placeholder="********"
                  value={NewPass}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  onChange={(e) => {
                    setNewPass(e.target.value);
                  }}
                />
              </div>
              <label className=" font-normal pb-1 ">Host email</label>
              {!EmailHostValid && touchedHost &&<label className=" font-normal pb-1 text-red-600 ml-4"> Proszę podać poprawny email </label>}
              <div class="input-group mb-4 rounded-md">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={Marketin_email_domain}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  onChange={(e) => {
                    setEmailHostValid(e.target.value.match(/\S+@\S+\.\S+/))
                    setMarketin_email_domain(e.target.value);
                  }}
                  onBlur={() => setTouchedHost(true)}
                />
              </div>
            </div>

            <div className="   fixed bottom-0 right-2   mt-8 pt-4 pb-4 mb-0 h-12 bg-white border-t-1 border-borderGray  z-10">
              <button
                type="button "
                className=" fixed bottom-2 right-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setWebsiteData()}
                disabled={!Active}
              >
                {Text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
