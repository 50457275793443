import React from 'react';
import { Bar } from 'react-chartjs-2';

function VerticalBarChart(params) {
    return (
        <Bar
            datasetIdKey='id'
            data={{
                labels: params.labels,
                datasets: params.datasets
            }}
            options={{
                plugins: {
                  title: {
                    display: true,
                    text: params.name
                  },
                },
                responsive: true,
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true
                  }
                }
              }}
        />

    )
}

export default VerticalBarChart;