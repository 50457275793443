import React, { useState, useEffect } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
import EmailCampainMenagment from "./EmailCampainMenagment";
import NavNew from "./NavNew";
import HeaderNew from "../Components/HeaderNew";

import SettingsNavNew from "./TailwindComponents/SettingsNavNew/SettingsNavNew";
export default function Email() {
  const URL = useSelector((e) => e.UserReducer.url);

  const [Email, setEmail] = useState("");
  const [Active, setActive] = useState(true);
  const [ComunicateText, setComunicateText] = useState("");

  const [Name, setName] = useState("");
  const [Subject, setSubject] = useState("");
  const [AllCampain, setAllCampain] = useState([]);

  useEffect(() => {
    get_all_email_campain();
  }, []);
  const get_all_email_campain = () => {
    axios
      .get(`${URL}/sqlite/get_email_campaigns.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        setAllCampain(response.data);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const createEmailCampaing = () => {
    if (Email !== "" && Name !== "" && Subject !== "") {
      axios
        .post(
          `${URL}/sqlite/create_email_campaing.php`,
          {
            name: Name,
            text: Email,
            subject: Subject,
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            clear();

            get_all_email_campain();

            setComunicateText("Utorzono nową kampanię email");
          } else {
            setComunicateText("Coś poszło nie tak, spróbuj ponownie");
          }
        })

        .catch((err) => {
          setComunicateText("Coś poszło nie tak, spróbuj ponownie");
          //console.log(err.response);
        });
    }
    {
      setComunicateText("Wpisz treść maila i nazwę kampani");
    }
  };

  const clear = () => {
    setEmail("");
    setName("");
    setSubject("");
  };
  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <SettingsNavNew
            tabs={[
              { name: "Kupony rabatowe", path: "/campaigns/cupons" },
              { name: "Kampanie e-mail", path: "/campaigns/email" },
              { name: "Kampanie SMS", path: "/campaigns/sms" },
              {
                name: "Apilkacja mobilna ",
                path: "/campaigns/pushnotification",
              },
              { name: "Baza klientów", path: "/campaigns/customermanagement" },
            ]}
            title="Kampanie promocyjne"
          />
          <NavNew />

          <div className="container-fluid">
            <div
              className="container-fluid display  px-0 position-relative "
              style={{ background: "white", minHeight: window.innerHeight }}
            >
              <div className="max-w-7xl mx-auto ">
                <div className="pl-5 pt-1 pr-5  ">
                  <div className=" mt-2">
                    <p className="text-darkgray font-semibold text-lg pt-3 font-weight: 600 font-i">
                      Nowa kampania e-mail
                    </p>
                  </div>

                  <label className=" font-normal pb-1 ">Nazwa kampanii (widoczna tylko dla Ciebie)</label>
                  <div class="mb-4 relative rounded-md">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder="Nazwa kampani "
                      value={Name}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  <label className=" font-normal pb-1 ">Temat wiadomoścci e-mail</label>
                  <div class="mb-4 relative rounded-md">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder="Temat emaila"
                      value={Subject}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                    />
                  </div>
                  <label className=" font-normal pb-1 ">Treść wiadomoścci e-mail</label>
                  <div class="mb-3 relative rounded-md shadow-sm ">
                    <CKEditor
                      editor={ClassicEditor}
                      data={Email}
                      onChange={(event, editor) => {
                        let data = editor.getData();
                        setEmail(data);
                      }}
                    />
                  </div>

                  <div className="  relative  ">
                    <div className="  absolute flex flex-col  bottom-100 right-0   mb-4 items-end">
                      <button
                        type="button"
                        className=" content-end px-3  py-2 border w-36 border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled={!Active}
                        onClick={createEmailCampaing}
                      >
                        Stwórz kampanię
                      </button>
                      <p>{ComunicateText}</p>
                    </div>
                  </div>

                  <div className=" mt-20">
                    <p className="text-darkgray font-semibold text-lg pt-3 font-weight:600 font-i">
                      Istniejące kampanie e-mailowe
                    </p>
                  </div>

                  <div className="w-full  ">
                    <div className="flex border-bottom ">
                      <div className="  font-bold text-base w-36 mr-3   pt-3 font-weight:400 d-flex align-items-left">
                        Nazwa kampanii
                      </div>
                      <div className=" font-bold text-base w-36 px-3 mr-2 pt-3 font-weight:400 d-flex align-items-left">
                        Temat e-mail
                      </div>
                      <div className="font-bold text-base w-36 mr-3  px-1  pt-3 font-weight:400 d-flex align-items-left">
                        Treść e-mail
                      </div>
                    </div>

                    <div className="do usunięcia"></div>
                    {AllCampain.map((element) => (
                      <EmailCampainMenagment
                        e={element}
                        func={get_all_email_campain}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
