import React, { useState, useEffect } from "react";
import CheckedElement from "./CheckedElement";
export default function AllElements({ menu, all_addition_categories, id }) {
  const [ID, setID] = useState(null);
  const [All_addition_categories, setAll_addition_categories] = useState(null);
  useEffect(() => {
    // console.log(id);
    setID(id);
    setAll_addition_categories(all_addition_categories);
  }, [id, all_addition_categories]);
  if (ID !== null && All_addition_categories !== null) {
    return Object.keys(All_addition_categories).map((element) => {
      if (menu.meal_categories[ID].addition_categories[element] === undefined) {
        return (
          <CheckedElement
            checked={false}
            sendElement={All_addition_categories[element]}
          />
        );
      } else {
        return (
          <CheckedElement
            checked={true}
            sendElement={All_addition_categories[element]}
          />
        );
      }
    });
  } else {
    return "";
  }
}
