import React, { useState, useEffect } from "react";
import HeaderNew from "../Components/HeaderNew";
import Distance from "./TailwindComponents/Distance/Distance";
import City from "./TailwindComponents/City/City";
import ChooseCalculate from "./TailwindComponents/ChooseCalculate/ChooseCalculate";
import { useSelector } from "react-redux";
import axios from "axios";
import SettingsNavNew from "./TailwindComponents/SettingsNavNew/SettingsNavNew";

const Delivery = () => {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);

  const [delivery, setDelivery] = useState([]);
  const [loading, setLoading] = useState(true);
  const [choosenDeliveryType, setChoosenDeliveryType] = useState("");

  useEffect(() => {
    getDeliveryPlaces();
  }, [choosenDeliveryType]);
  const getDeliveryPlaces = async () => {
    await axios
      .get(`${SERVER_URL}/${ResteurantCode}/delivery_places`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        if (response.data.places) {
          if (Object.keys(response.data.places).length > 0) {
            setChoosenDeliveryType("city");
          } else if (Object.keys(response.data.ranges).length > 0) {
            setChoosenDeliveryType("distance");
          }
        }
        setDelivery(response.data);
        setLoading(false);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  // const [City, setCity] = useState('')
  // const [FreeAbove, setFreeAbove] = useState('')
  // const [Price, setPrice] = useState('')

  // const ValidCity = Value => {
  // 	setCity(Value)
  // }
  // const ValidPrice = Value => {
  // 	setPrice(Value)
  // 	// //console.log(Value);
  // }
  // const ValidFreeAbove = Value => {
  // 	setFreeAbove(Value)
  // 	////console.log(Value);
  // }
  // const removeCity = id => {
  // 	////console.log(id);
  // 	axios
  // 		.delete(`${SERVER_URL}/${ResteurantCode}/delivery_place/${id}`)
  // 		.then(response => {
  // 			////console.log(response);
  // 			if (response.status === 204 && response.statusText === 'No Content') {
  // 				//  //console.log(response);
  // 				getDeliveryPlaces()
  // 			}
  // 		})
  // 		.catch(err => {
  // 			//console.log(err.response);
  // 		})
  // }
  // const addDelivery = () => {
  // 	if (
  // 		City !== '' &&
  // 		FreeAbove !== '' &&
  // 		Price !== '' &&
  // 		ZL !== '' &&
  // 		ZL2 !== ''
  // 	) {
  // 		setActive(false)
  // 		setText('Czekaj')
  // 		let json = {
  // 			name: City,
  // 			delivery_price: Price,
  // 			free_above: FreeAbove,
  // 			is_place: true,
  // 		}
  // 		////console.log(json);
  // 		axios
  // 			.post(`${SERVER_URL}/${ResteurantCode}/delivery_places`, json, {
  // 				'Content-Type': 'application/json',
  // 			})
  // 			.then(response => {
  // 				if (response.status === 201 && response.statusText === 'Created') {
  // 					setActive(true)
  // 					setText('Dodaj strefę')
  // 					setComunicateText('Dodano! Możesz teraz dodać kolejną strefę')
  // 					getDeliveryPlaces()
  // 					setCity('')
  // 					setGR('')
  // 					setGR2('')
  // 					setZL('')
  // 					setZL2('')
  // 					setFreeAbove('')
  // 					setPrice('')
  // 				}
  // 			})
  // 			.catch(err => {
  // 				//console.log(err.response);
  // 			})
  // 	} else if (pickUp === true) {
  // 		setActiveFields(true)
  // 		let arr = Object.values(Delivery)
  // 		let con = false
  // 		let arrayRes = []

  // 		arr.map(el => {
  // 			if (el.name.indexOf('Odbiór osobisty') > -1) {
  // 				arrayRes.push(el.name.indexOf('Odbiór osobisty') > -1)
  // 			}
  // 		})
  // 		console.log(arrayRes, 'result')

  // 		if (arrayRes.length === 0) {
  // 			setText('Czekaj')
  // 			let json = {
  // 				name: 'Odbiór osobisty',
  // 				delivery_price: 0,
  // 				free_above: 0,
  // 				is_place: true,
  // 			}
  // 			////console.log(json);
  // 			axios
  // 				.post(`${SERVER_URL}/${ResteurantCode}/delivery_places`, json, {
  // 					'Content-Type': 'application/json',
  // 				})
  // 				.then(response => {
  // 					// //console.log(response);
  // 					if (response.status === 201 && response.statusText === 'Created') {
  // 						//  //console.log(response);

  // 						setActive(true)
  // 						setText('Dodaj strefę')
  // 						setPickUp(false)
  // 						setActiveFields(false)
  // 						setComunicateText('Dodano! Możesz teraz dodać kolejną strefę')
  // 						getDeliveryPlaces()
  // 					}
  // 				})
  // 				.catch(err => {
  // 					//console.log(err.response);
  // 				})
  // 		} else {
  // 			setComunicateText('Odbiór osobisty już istnieje!')
  // 		}
  // 	} else {
  // 		setComunicateText('Uzupełnij wszytkie pola')
  // 	}
  // }
  // const [GR, setGR] = useState('')
  // const [ZL, setZL] = useState('')
  // const ValidGR = value => {
  // 	if (value.length < 3) {
  // 		setGR(value)
  // 		sendPrice1(2, value)
  // 	}
  // }
  // const sendPrice1 = (x, value) => {
  // 	if (x === 1) {
  // 		let p = FreeAbove
  // 		p = value + '.' + GR
  // 		ValidFreeAbove(p)
  // 	} else if (x === 2) {
  // 		let p = FreeAbove
  // 		p = ZL + '.' + value
  // 		ValidFreeAbove(p)
  // 	}
  // }
  // const [GR2, setGR2] = useState('')
  // const [ZL2, setZL2] = useState('')
  // const ValidGR2 = value => {
  // 	if (value.length < 3) {
  // 		setGR2(value)
  // 		sendPrice2(2, value)
  // 	}
  // }
  // const sendPrice2 = (x, value) => {
  // 	if (x === 1) {
  // 		let p = FreeAbove
  // 		p = value + '.' + GR2
  // 		ValidPrice(p)
  // 	} else if (x === 2) {
  // 		let p = FreeAbove
  // 		p = ZL2 + '.' + value
  // 		ValidPrice(p)
  // 	}
  // }
  // const [pickUp, setPickUp] = useState(false)

  // const handleChange = () => {
  // 	setPickUp(current => !current)
  // 	setActiveFields(!ActiveFields)
  // 	setCity('')
  // 	setGR('')
  // 	setGR2('')
  // 	setZL('')
  // 	setZL2('')
  // 	setFreeAbove('')
  // 	setPrice('')
  // }




  const getType = async (value) => {
    if (value === "distance") {
      let json = {
        uses_google_maps: true,
      };
      await axios
        .patch(`${SERVER_URL}/${ResteurantCode}/restaurant/`, json, {
          "Content-Type": "application/json",
        })
        .then((response) => {
          console.log(response);
        })

        .catch((err) => {
          //console.log(err.response);
        });
    } else if (value === "city") {
      let json = {
        uses_google_maps: false,
      };
      await axios
        .patch(`${SERVER_URL}/${ResteurantCode}/restaurant/`, json, {
          "Content-Type": "application/json",
        })
        .then((response) => {
          console.log(response);
        })

        .catch((err) => {
          //console.log(err.response);
        });
    }
    setChoosenDeliveryType(value);
  };

  const renderActiveZones = () => {
    if (
      Object.keys(delivery.places).length > 0 ||
      choosenDeliveryType === "city"
    ) {
      return <Distance setChoosenDeliveryType={setChoosenDeliveryType} />;
    }
    
    if (
      Object.keys(delivery.ranges).length > 0 ||
      choosenDeliveryType === "distance"
    ) {
      return <City setChoosenDeliveryType={setChoosenDeliveryType} />;
    }
    return <></>;
  };

  return (
    <>
      <HeaderNew />

      {!loading &&
      Object.keys(delivery.ranges).length === 0 &&
      Object.keys(delivery.places).length === 0 &&
      choosenDeliveryType === "" ? (
        <div className="m-auto">
          <ChooseCalculate getFinalVal={getType} />
          <div className="flex flex-row mr-2 pt-3">
            <p className="text-indigo mr-3 text-sm">Dowiedz się więcej </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#4F46E5"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="max-w-7xl mx-auto">
        <SettingsNavNew
          tabs={[
            { name: "Strefy dostaw", path: "/customize/delivery" },
            { name: "Dane firmy", path: "/customize/companyinfo" },
            { name: "Alerty", path: "/customize/alerts" },
            { name: "Wygląd", path: "/customize/style" },
            { name: "Dodatkowe teksty", path: "/customize/texts" },
			      { name: "Legenda", path: "/customize/icons" },
			 
          ]}
          title="Ustawienia"
        />
        {loading ? <></> : renderActiveZones()}
      </div>
    </>
  );
};

export default Delivery;
