import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HeaderIconSelector from "./HeaderIconSelector";
import AllElements from "./AllElements";
import axios from "axios";
export default function EditSelectedCategory({ id }) {
  const count = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
  ];
  const sellect = (element) => {
    // console.log(element);
    setCategoryIcon(element);
  };
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  const Menu = useSelector((e) => e.MenuReducer.menuToEdit);
  const Additions = useSelector((e) => e.MenuReducer.aditionsCategory);
  const Code = useSelector((e) => e.UserReducer.resteurantCode);
  const [EditableCategory, setEditableCategory] = useState(null);
  const [CategoryName, setCategoryName] = useState("");
  const [CategoryIcon, setCategoryIcon] = useState("");
  const [All_addition_categories, setAll_addition_categories] = useState(null);
  const [Checked, setChecked] = useState(null);
  const [Bool, setBool] = useState(true);
  useEffect(() => {
    if (Menu !== null) {
      setBool(false);
      setEditableCategory(Menu.meal_categories[id]);
      setCategoryName(Menu.meal_categories[id].name);
      setCategoryIcon(Menu.meal_categories[id].icon);
      setAll_addition_categories(Menu.all_addition_categories);
      setChecked(Menu.meal_categories[id].addition_categories);
    }
  }, [Menu, id]);

  const ValidCategoryName = (value) => {
    setCategoryName(value);
  };
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Zapisz zmiany");
  const [ComunicateText, setComunicateText] = useState("");
  const editCatagory = () => {
    if (CategoryName !== null && CategoryName !== "" && CategoryIcon !== "") {
      setActive(false);
      setText("Czekaj");
      let json = {
        name: CategoryName,
        icon: CategoryIcon,
        addition: Additions,
      };
      // console.log(json);
      axios
        .put(
          `${SERVER_URL}/${Code}/meal_category/${id}`,
          json,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.status === 200 && response.statusText === "OK") {
            //  console.log(response);
            setActive(true);
            setText("Zapisz zmiany");
            setComunicateText("Zapisano zmiany");
          }
        })
        .catch((err) => {
         // console.log(err.response);
        });
    } else {
      setComunicateText("Coś poszło nie tak");
    }
  };
  if (EditableCategory !== null) {
    return (
      <div className="row mx-0 px-0">
        <div className="col-12">
          <div class="form-group">
            <label class="Bold h5" for="exampleInputEmail1">
              Nazwa kategorii
            </label>
            <input
              class="form-control"
              id="exampleInputEmail1"
              value={CategoryName}
              onChange={(e) => {
                ValidCategoryName(e.target.value);
              }}
              aria-describedby="emailHelp"
            />
          </div>
          <div className="row mx-0 px-0">
            <div className="col-12 col-lg-6">
              <div class="form-group Bold h5">
                <label for="exampleInputEmail1">Aktualna ikona:</label>
                <HeaderIconSelector icon={CategoryIcon} color="#000000" />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <p className="pl-3">Wybierz nową:</p>
              <div class="input-group mb-3">
                {count.map((element) => (
                  <div
                    id={element}
                    className="clicked ml-1"
                    style={{
                      width: "56px",
                      height: "56px",
                      cursor: "pointer",
                    }}
                    onClick={() => sellect(element)}
                  >
                    <HeaderIconSelector icon={element} color="#000000" />
                  </div>
                ))}
              </div>{" "}
            </div>
          </div>
        </div>{" "}
        <div className="col-12">
          <h5 className="Bold"> Kategorie dodatków</h5>
          <div className="row">
            {
              <AllElements
                menu={Menu}
                all_addition_categories={All_addition_categories}
                id={id}
              />
            }
          </div>
        </div>
        <div className="col-12">
          <button
            type="button"
            class="btn btn-success mt-5"
            onClick={() => editCatagory()}
            disabled={!Active}
          >
            {Text}
          </button>
          <p>{ComunicateText}</p>
        </div>
      </div>
    );
  } else {
    return "";
  }
}
